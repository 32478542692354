import {createSlice} from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";

//const history = useHistory();

const initialDeliveriesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  deliveryForEdit: undefined,
  lastError: null,
  deliveryUpdated: false,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const deliveriesSlice = createSlice({
  name: "deliveries",
  initialState: initialDeliveriesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getDeliveryById
    deliveryFetched: (state, action) => {
      state.actionsLoading = false;
      state.deliveryForEdit = action.payload.deliveryForEdit;
      state.error = null;
    },

    // createDelivery
    deliveryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      //state.entities.push(action.payload.delivery);
      state.deliveryUpdated=!state.deliveryUpdated;
    },

    deliveryConfirmed: (state, action) => {
      state.deliveryUpdated = !state.deliveryUpdated;
      state.error = null;
      
    },    
    ordersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },    
  }
});
