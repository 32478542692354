
import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useReportsUIContext } from "../ReportsUIContext";
import Select from 'react-select'
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import * as reportsActions from '../../_redux/reportsActions'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { WaybillReportToCSV } from "./WaybillReportToCSV";
import { Form } from "react-bootstrap";
import '../../styles.css'

const prepareFilter = (queryParams, values) => {
    const { zone_id, shipping_type, order_creation_from , order_creation_to, dispatched_date_from , dispatched_date_to, shipping_carrier, business_unit_id, details } = values;
    
    const newQueryParams = { ...queryParams, filter:{...queryParams.filter} };

    if(zone_id && zone_id !== ""){
      newQueryParams.filter.zone_id = zone_id;
    }
    else{
      newQueryParams.filter.zone_id = undefined;
    }
    
    // Filter by shipping_type
    if(shipping_type && shipping_type !== ""){
      newQueryParams.filter.shipping_type_id = shipping_type;
    }
    else{      
      newQueryParams.filter.shipping_type_id = undefined;
    }

    if(shipping_carrier && shipping_carrier !== ""){
      newQueryParams.filter.shipping_carrier_id = shipping_carrier;
    }
    else{      
      newQueryParams.filter.shipping_carrier_id = undefined;
    }

    if(order_creation_from  && order_creation_from  !== ""){
      newQueryParams.filter.order_creation_from  = moment(order_creation_from ).format("YYYYMMDD");
    }
    else{      
      newQueryParams.filter.order_creation_from  = undefined;
    }

    if(order_creation_to && order_creation_to !== ""){
      newQueryParams.filter.order_creation_to = moment(order_creation_to).format("YYYYMMDD");
    }
    else{      
      newQueryParams.filter.order_creation_to = undefined;
    }

    if(dispatched_date_from  && dispatched_date_from  !== ""){
      newQueryParams.filter.dispatched_date_from  = moment(dispatched_date_from ).format("YYYYMMDD");
    }
    else{      
      newQueryParams.filter.dispatched_date_from  = undefined;
    }

    if(dispatched_date_to && dispatched_date_to !== ""){
      newQueryParams.filter.dispatched_date_to = moment(dispatched_date_to).format("YYYYMMDD");
    }
    else{      
      newQueryParams.filter.dispatched_date_to = undefined;
    }
    if(business_unit_id && business_unit_id !== ""){
      newQueryParams.filter.business_unit_id = business_unit_id;
    }
    else{
      newQueryParams.filter.business_unit_id = undefined;
    }    

    if(details && details !== ""){
      newQueryParams.filter.details = details;
    }
    else{
      newQueryParams.filter.details = undefined;
    }   
    return newQueryParams;
};

export function WaybillReportFilters({ listLoading }) {
  const [createdFrom, setCreatedFrom] = useState(null);
  const [createdTo, setCreatedTo] = useState(null);
  const [dispatchedFrom, setDispatchedFrom] = useState(null);
  const [dispatchedTo, setDispatchedTo] = useState(null);
  const [newQueryParams, setNewQueryParams] = useState(null);
  const [details, setDetails] = useState(false);
  
  const reportsUIContext = useReportsUIContext();
  const reportsUIProps = useMemo(() => {
    return {
      setActualReportQueryParams: reportsUIContext.setActualReportQueryParams,
      actualReportQueryParams: reportsUIContext.actualReportQueryParams,
      setWithDetails:reportsUIContext.setWithDetails,
      withDetails:reportsUIContext.withDetails
    };
  }, [reportsUIContext]);

  const { entities, business_units } = useSelector(
      (state) => ({
        entities: state.reports.report.entities,
        business_units: state.helpers.BusinessUnits
      }),
      shallowEqual
  );

  const applyFilter = (values) => {
    // const newQueryParams = prepareFilter(reportsUIProps.actualReportQueryParams, values);

    // if (!isEqual(newQueryParams, reportsUIProps.actualReportQueryParams)) {
    //   newQueryParams.pageNumber = 1;
    //   reportsUIProps.setActualReportQueryParams(newQueryParams);
    // }
    setNewQueryParams(prepareFilter(reportsUIProps.actualReportQueryParams, values))
  };

  const zonasUI = [
    {value:1, label:'CABA'},
    {value:2, label:'GBA'},
    {value:3, label:'Interior'},
    {value:7, label:'Tierra del Fuego'}
  ]

  const shipping_types = [
      {value:1, label:'Envío normal'},
      {value:2, label:'Envío express'},
      {value:3, label:'Retiro en Farmacia'},
      {value:4, label:'Punto Pickit'},
  ]

  const shipping_carriers = [
      {value:1, label:'Pharmatrans'},
      {value:2, label:'Urbano'},
      {value:3, label:'Oca'},
      {value:4, label:'Tornus'},,
      {value:5, label:'Pickit'},
  ]

  const dispatch = useDispatch();

  const executeReport=()=>{

    //dispatch(reportsActions.fetchReportsByName("waybills", reportsUIProps.actualReportQueryParams));
    //if (!isEqual(newQueryParams, reportsUIProps.actualReportQueryParams)) {
      if(newQueryParams){
        newQueryParams.pageNumber = 1;
        reportsUIProps.setActualReportQueryParams(newQueryParams);
        dispatch(reportsActions.fetchReportsByName("waybills", newQueryParams));
      }
      else{
        dispatch(reportsActions.fetchReportsByName("waybills", reportsUIProps.actualReportQueryParams));
      }
      reportsUIProps.setWithDetails(details);
    //}

  }

  const stylesSelect = { 
    menuPortal: (base) => ({ ...base, zIndex: 9999}),
    control: (css) => ( 
        ({...css,  width: "100%", marginLeft:"2px"})),
        menu: ({ width, ...css }) => ({
        ...css,
        width: "max-content"       
        }),
        // Add padding to account for width of Indicators Container plus padding
        option: (css) => ({ ...css, width: "100%" }),
  
  }

  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Selling=0/Sold=1
          condition: "", // values => All=""/New=0/Used=1
          searchText: "",
          details:undefined
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right" >
            
            <div className="form-group row">

                <div className="col-lg-3">
                    <ReactDatePicker
                        autoComplete='off'
                        className="form-control"
                        style={{ width: "100%" }}
                        name="createdFrom"
                        selected={createdFrom}
                        maxDate={createdTo?(createdTo>new Date()?new Date():createdTo):new Date()}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => {
                        setCreatedFrom(date);
                        setFieldValue("order_creation_from", date?date:null);
                        handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                  Pedidos <b>desde</b>
                    </small>
                </div>
              
                <div className="col-lg-3">
                    <ReactDatePicker
                        autoComplete='off'
                        className="form-control"
                        style={{ width: "100%" }}
                        name="createdTo"
                        selected={createdTo}
                        minDate={createdFrom}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) =>  {
                            setCreatedTo(date);
                            setFieldValue("order_creation_to", date?date:null);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                    Pedidos <b>hasta</b>
                    </small>
                </div>

                <div className="col-lg-3">
                    <ReactDatePicker
                        autoComplete='off'
                            className="form-control"
                            style={{ width: "100%" }}
                            name="dispatchedFrom"
                            selected={dispatchedFrom}
                            maxDate={dispatchedTo?(dispatchedTo>new Date()?new Date():dispatchedTo):new Date()}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => {
                            setDispatchedFrom(date);
                            setFieldValue("dispatched_date_from", date?date:null);
                            handleSubmit();
                            }}
                        />
                    <small className="form-text text-muted">
                    Despacho <b>desde</b>
                    </small>
                </div>
                <div className="col-lg-3">
                    <ReactDatePicker
                        autoComplete='off'
                        className="form-control"
                        style={{ width: "100%" }}
                        name="dispatchedTo"
                        selected={dispatchedTo}
                        minDate={dispatchedFrom}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) =>  {
                            setDispatchedTo(date);
                            setFieldValue("dispatched_date_to", date?date:null);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                    Despacho <b>hasta</b>
                    </small>
                </div>                


            </div>

            <div className="form-group row">
            <div className="col-lg-3">
                    <Select 
                        options={zonasUI} 
                        style={stylesSelect}
                        isMulti="true"
                        menuPortalTarget={document.body}
                        autoComplete="nope" 
                        onChange={(e) => {
                            setFieldValue("zone_id", e?e.map(item=>item.value.toString()
                            .replace("2", "2,8,9,10")
                            .replace("3", "3,4,5,6,11,12")).join(','):null);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                    <b>Zona</b>
                    </small>
                </div>
                
                <div className="col-lg-3">
                    <Select 
                        options={shipping_carriers} 
                        isMulti="true"
                        menuPortalTarget={document.body}
                        autoComplete="nope" 
                        onChange={(e) => {
                            setFieldValue("shipping_carrier", e?e.map(item=>item.value).join(','):null);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                    <b>Empresa</b>
                    </small>
                </div>

                <div className="col-lg-3">
                    <Select 
                        options={shipping_types} 
                        isMulti="true"
                        menuPortalTarget={document.body}
                        autoComplete="nope" 
                        onChange={(e) => {
                            setFieldValue("shipping_type", e?e.map(item=>item.value).join(','):null);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                    <b>Tipo de Envío</b>
                    </small>
                </div>
                <div className="col-lg-3">
                  <Select 
                      // defaultValue={[business_units[0]]}
                      options={business_units} 
                      // isMulti="true"
                      menuPortalTarget={document.body}
                      autoComplete="nope" 
                      onChange={(e) => {
                          //setFieldValue("business_unit_id", e?e.map(item=>item.value).join(','):null);
                          setFieldValue("business_unit_id", e?e.value:null);
                          handleSubmit();
                      }}
                  />
                  <small className="form-text text-muted">
                    Filtro por <b>Unidad de Negocio</b>
                  </small>
                </div> 


            </div>

            <div className="form-group row">
                <div className="col">
                  <Form.Check 
                          type="switch"
                          id="detail"
                          label="Con detalle de productos"
                          defaultChecked={false}
                          onChange={(e)=>{ 
                            setFieldValue("details", e.target.checked?1:undefined)
                            setDetails(e.target.checked?true:false)
                            handleSubmit();
                          }}
                      />
                </div>
            
                <div className="col float-right">

                    <button type="button" onClick={executeReport} className="btn btn-light float-right m-2" > 
                        <i className="fa fa-play text-primary"></i>
                        Ejecutar
                    </button>     

                    { entities && entities.length>0 && 
                      <WaybillReportToCSV></WaybillReportToCSV>
                    }

                    {/* <button type="button" onClick={()=>{}} className="btn btn-light float-right m-2" > 
                        <i className="fa fa-backspace text-default"></i>
                        Limpiar Filtros
                    </button>              */}
                </div>  
            </div>

          </form>
        )}
      </Formik>
    </>
  );
}



