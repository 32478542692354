export const ReportStatusCssClasses = ["success", "info", ""];
export const ReportConditionCssClasses = ["success", "danger", ""];
export const defaultSorted = [{ dataField: "id", report: "desc" }];
export const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
];
export const initialFilter = {
  filter: {
    
  },
  pageNumber: 1,
  pageSize: 10
};


