/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import {Dropdown} from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {separadorMiles} from "../../Global/helpers";


export function TopProductsWidget({ className, entities }) {
    //console.log(entities)
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">Top 10 de Productos</h3>

        </div>
        {/* Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table className="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th>Producto</th>
                  <th className="text-right">Ventas</th>
                  <th className="text-right">Monto($)</th>
                </tr>
              </thead>
              <tbody>
              { entities && entities.topProducts && entities.topProducts.map((producto, index) => (
                            <tr key={index}>
                                <td>{producto.name}</td>
                                <td className="text-right">{separadorMiles(parseInt(producto.value))}</td>
                                <td className="text-right">{separadorMiles(parseInt(producto.total))}</td>
                            </tr>
                        ))}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
      </div>
    </>
  );
}


