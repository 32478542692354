import axios from "axios";

export const ORDERS_URL =`${process.env.REACT_APP_API_URL}/orders`;// "api/orders";
export const WAVES_URL =`${process.env.REACT_APP_API_URL}/waves`;

export const ORDERS_STATE_URL =`${process.env.REACT_APP_URL_SHOP}/logistica/estado`;

// CREATE =>  POST: add a new order to the server
export function createOrder(order) {
  return axios.post(ORDERS_URL, { order });
}

export function getUrlOrderLogisticState(hash) {
  return `${ORDERS_STATE_URL}?hash=${hash}`;
}

let cancelToken;

export function getOrders(params) {
    return (async () => {
        try {
            if (typeof cancelToken != typeof undefined) {
              cancelToken.cancel("Cancelado por new Request.")
            }
            cancelToken = axios.CancelToken.source();

            const resp = await axios.get(ORDERS_URL, { params, cancelToken: cancelToken.token });

            return resp;

        } catch (err) {
          
            if (axios.isCancel(err)) {
              //console.log('Request canceled');
            } else {
              // handle error
            }
            throw err;
        }
    })();
}

export function getCancelledOrders(params) {
  return (async () => {
      try {
          if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Cancelado por new Request.")
          }
          cancelToken = axios.CancelToken.source();

          const resp = await axios.get(ORDERS_URL + '/canceled', { params, cancelToken: cancelToken.token });

          return resp;

      } catch (err) {
        
          if (axios.isCancel(err)) {
            //console.log('Request canceled');
          } else {
            // handle error
          }
          throw err;
      }
  })();
}

// export function getOrders(params) {
//   return axios.get(ORDERS_URL, { params });
// }



export function getOrderById(orderId) {
  return axios.get(`${ORDERS_URL}/${orderId}?addTracking=1`);
}

export function getOrderHistory(id){
  return axios.get(ORDERS_URL + '/' + id +'?addTracking=1');
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOrders(queryParams) {
  return axios.post(`${ORDERS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateOrder(order) {
  return axios.put(`${ORDERS_URL}/${order.id}`, { order });
}

// UPDATE Status
export function updateStatusForOrders(ids, status) {
  return axios.post(`${ORDERS_URL}/updateStatusForOrders`, {
    ids,
    status
  });
}

export function updateCustomerNote(obTransfer) {
  return axios.post(`${ORDERS_URL}/${obTransfer.order_id}/shipping/notes`, {note:obTransfer.note});
}

// DELETE => delete the order from the server
export function deleteOrder(orderId) {
  return axios.delete(`${ORDERS_URL}/${orderId}`);
}

// DELETE orders by ids
export function deleteOrders(ids) {
  return axios.post(`${ORDERS_URL}/deleteOrders`, { ids });
}


export function addToWave(params) {
  return axios.put(`${WAVES_URL}/${params.waveId}`, {orders:params.orderIds});
}


export function orderDelivered(params) {
  return axios.post(`${ORDERS_URL}/delivered`, 
    {
      orders:params.orderIds
    });
}
export function orderReturned(params) {
  return axios.post(`${ORDERS_URL}/returned`, 
    { 
      order_id:params.order_id, 
      returned_reason_id:params.returned_reason_id,
      delivered_returning:params.delivered_returning
    });
}
export function orderReturningPending(params) {
  return axios.post(`${ORDERS_URL}/returning-pending`, 
    {
      orders:params.orderIds
    });
}

export function setPayment(order_id, paid) {
  if(paid){
    return axios.post(`${ORDERS_URL}/` + order_id + '/paid' );
  }
  else{
    return axios.delete(`${ORDERS_URL}/` + order_id + '/paid' );
  }
}


export function postInternalNote(obTransfer){
  return axios.post(ORDERS_URL + '/' + obTransfer.order_id + '/notes', {note:obTransfer.note});
}


export function updateShipping(order) {
  return axios.put(`${ORDERS_URL}/${order.id}/shipping`, { shipping_type_id:order.shipping_type_id });
}

export function getTrackingUrbano(order_id){
  return axios.get(ORDERS_URL + '/' + order_id +'/shipping/urbano/tracking');
}

export function getTrackingOca(order_id){
  return axios.get(ORDERS_URL + '/' + order_id +'/shipping/oca/tracking');
}

export function getTrackingPickit(order_id){
  return axios.get(ORDERS_URL + '/' + order_id +'/shipping/pickit/tracking');
}

export function getTrackingOnroad(order_id){
  return axios.get(ORDERS_URL + '/' + order_id +'/shipping/onroad/tracking');
}

export function getTrackingTornus(order_id){
  return axios.get(ORDERS_URL + '/' + order_id +'/shipping/tornus/tracking');
}

export function cancelOrder(order_id, message){
  return axios.post(ORDERS_URL + '/' + order_id +'/cancel', {message});
}

export function activateOrder(order_id){
  return axios.post(ORDERS_URL + '/' + order_id +'/activate');
}

export function getSummary(orderId) {
  return axios.get(`${ORDERS_URL}/${orderId}/summary`);
}

export function sendToPending(order_id){
  return axios.post(ORDERS_URL + '/' + order_id +'/logistic-state/pending ', {});
}
