import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import * as productsCrud from '../../../_redux/productsCrud'


export function ProductEditCard({history}) {
    const { id } = useParams();
    const [medicamento, setMedicamento] = useState({});
    const [producto, setProducto] = useState({});

    const fetchData = async()=>{
        const res = await productsCrud.getProductById(id)
        if(res && res.data && res.data.results){
            setProducto(res.data.results[0]);
            if(res.data.results[0].article){
                setMedicamento(res.data.results[0].article);
            }
        }
    }
    
    useEffect(()=>{
        fetchData();
    }, [])
  
    return (
        <Card>
        <CardHeader title={medicamento.nom || ""}>
            <CardHeaderToolbar>
                <div className="text-right">
                    <button type="button" className="btn btn-success" onClick={()=>{history.goBack()}} >
                        <i className="fa fa-chevron-left"></i> Volver 
                    </button>
                </div>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
            <Row>
                <Col>
                    <div className="form-group">
                        <label><b>Nombre</b></label>
                        <Form.Control type="text"  disabled name="note" value={producto.name || ""}  className="form-control" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label><b>SKU</b></label>
                        <Form.Control type="text"  disabled name="note" value={producto.sku || ""}  className="form-control" />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label><b>Precio</b></label>
                        <Form.Control type="text"  disabled name="note" value={producto.price || ""}  className="form-control" />
                    </div>
                </Col>
            </Row>
            { producto && producto.product_items && producto.product_items.length>1 && 
            <>
                <p className="mb-5 mt-10 text-center"><b>Composición</b></p>
                <Row>
                    <Col>
                        <div className="form-group text-center mb-2">
                            <label><b>Nombre</b></label>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group text-center mb-2">
                            <label><b>Cantidad</b></label>
                        </div>
                    </Col>
                </Row>
                {producto.product_items.map((item, idx)=>(
                    <Row id={idx}>
                        <Col>
                            <div className="form-group">
                                <Form.Control type="text"  disabled name="note" value={item.name || ""}  className="form-control" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <Form.Control type="text"  disabled name="note" value={item.qty || ""}  className="form-control text-center" />
                            </div>
                        </Col>
                    </Row>

                    ))}
            </>
            }
            { medicamento && medicamento.nom && 
            <>
                <hr></hr>
                <p className="mb-5 mt-10 text-center"><b>Datos Alfabeta</b></p>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label><b>Nombre Comercial</b></label>
                            <Form.Control type="text"  disabled name="note" value={medicamento.nom || ""}  className="form-control" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label><b>Presentación</b></label>
                            <Form.Control type="text"  disabled name="note" value={medicamento.pres || ""}  className="form-control" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label><b>Potencia</b></label>
                            <Form.Control type="text"  disabled name="note" value={medicamento.pot?(medicamento.pot.valor + ' ' + medicamento.pot.unidad):""}  className="form-control" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label><b>Forma</b></label>
                            <Form.Control type="text"  disabled name="note" value={medicamento?.form?.des} className="form-control" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label><b>Heladera</b></label>
                            <Form.Control type="text"  disabled name="note" value={medicamento.hel || ""} className="form-control" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label><b>Laboratorio</b></label>
                            <Form.Control type="text"  disabled name="note" value={medicamento.lab? medicamento.lab.name : ""} className="form-control" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label><b>Monodroga</b></label>
                            <Form.Control type="text"  disabled name="note" value={medicamento.monodroga || ""} className="form-control" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label><b>Código de barras</b></label>
                            <Form.Control type="text"  disabled name="note" value={medicamento.bars || ""} className="form-control" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label><b>GTIN</b></label>
                            <Form.Control type="text"  disabled name="note" value={medicamento.gtins || ""} className="form-control" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label><b>Cód. Alfabeta</b></label>
                            <Form.Control type="text"  disabled name="note" value={medicamento.reg || ""} className="form-control" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label><b>Precio</b></label>
                            <Form.Control type="text"  disabled name="note" value={medicamento.precio?'$'+medicamento.precio : ""} className="form-control" />
                        </div>
                    </Col>
                </Row>
            </>
            }


        </CardBody>
        </Card>
    );
}

