import axios from "axios";

export const REPORTS_URL =`${process.env.REACT_APP_API_URL}/reports`;

let cancelToken;

// READ
export function getReports(params) {
  return axios.get(REPORTS_URL,{ params });
}

export function getReportById(orderId) {
  return axios.get(`${REPORTS_URL}/${orderId}`);
}

export function getReportByName(name, params) {
    return (async () => {
        try {
            if (typeof cancelToken != typeof undefined) {
              cancelToken.cancel("Cancelado por new Request.")
            }
            cancelToken = axios.CancelToken.source();

            const resp = await axios.get(`${REPORTS_URL}/${name}`,{ params });
            return resp;

        } catch (err) {
          
            if (axios.isCancel(err)) {
              //console.log('Request canceled');
            } else {
              // handle error
            }
            throw err;
        }
    })();
}



// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result



export function findReport(params) {
  return (async () => {
      try {
          if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Cancelado por new Request.")
          }
          cancelToken = axios.CancelToken.source();

          const resp = await axios.post(`${REPORTS_URL}/find`, { params, cancelToken: cancelToken.token  });
          return resp;

      } catch (err) {
        
          if (axios.isCancel(err)) {
            //console.log('Request canceled');
          } else {
            // handle error
          }
          throw err;
      }
  })();
}

// export function findReport(queryParams) {
//   return axios.post(`${REPORTS_URL}/find`, { queryParams });
// }

