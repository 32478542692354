import * as reportsCrud from "./reportsCrud";
import {reportsSlice, callTypes} from "./reportsSlice";

const {actions} = reportsSlice;

export const fetchReports = queryParams => dispatch => {
  //console.log(queryParams);
  dispatch(actions.startCall({ callType: callTypes.list }));
  return reportsCrud
    .getReports(queryParams)
    .then(response => {
      const totalCount = response.data.metadata.count;
      const entities = response.data.results;
      dispatch(actions.reportsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find reports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};



export const fetchReportsById = id => dispatch => {

  dispatch(actions.startCall({ callType: callTypes.list }));
  return reportsCrud
    .getReportById(id)
    .then(response => {
      const report = response.data.results;
      dispatch(actions.reportFetched({ report }));
    })
    .catch(error => {
      error.clientMessage = "Can't find report";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchReportsByName = (name, queryParams) => dispatch => {

    dispatch(actions.startCall({ callType: callTypes.list }));
    return reportsCrud
      .getReportByName(name, queryParams)
      .then(response => {
        const totalCount = response.data.metadata.count;
        const entities = response.data.results.map((item, index)=>({...item, idx:index}));
        dispatch(actions.reportFetched({ totalCount, entities }));
      })
      .catch(error => {
        error.clientMessage = "Can't find report";
        dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
  };
  

  export const reset = () => dispatch => {
    dispatch(actions.reset());
  };

