import React, {useMemo, useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { WaybillReportFilters } from "./WaybillReportFilter";
import * as reportsActions from '../../_redux/reportsActions'

import { WaybillReportTable } from "./WaybillReportTable";
import { useReportsUIContext } from "../ReportsUIContext";
import { initialFilter } from "../ReportsUIHelpers";




export function WaybillReportCard({history}) {

    const reportsUIContext = useReportsUIContext();
    const reportsUIProps = useMemo(() => {
      return {
        setQueryParams: reportsUIContext.setActualReportQueryParams
      };
    }, [reportsUIContext]);

    const dispatch = useDispatch();

    const goBack = ()=>{
        dispatch(reportsActions.reset())
        reportsUIProps.setQueryParams(initialFilter);
        history.goBack()
    }


    return (
        <Card>
        <CardHeader title="Reporte de Despachos">

            <CardHeaderToolbar>
                <button type="button" onClick={()=>goBack()} className="btn btn-light mb-5"> <i className="fa fa-arrow-left"></i>
                    Volver
                </button> 
            </CardHeaderToolbar>

        </CardHeader>
        <CardBody>
            
            <WaybillReportFilters />

            <WaybillReportTable />

        </CardBody>
        </Card>
    );
}
