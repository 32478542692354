
import React, { useMemo, useRef, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import Select from 'react-select'
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as pharmaCrud from "../../_redux/pharma/pharmaCrud";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import { AccountPrintPage } from "./AccountPrintPaje";




export function PharmaAccountFilter({entities, setEntities}) {
  const [createdFrom, setCreatedFrom] = useState(new Date());
  const [createdTo, setCreatedTo] = useState(new Date());
  const [dataCSV, setDataCSV] = useState([]);
  const [loading, setLoading] = useState(false);
  const csvRef = useRef();

  const printRef = useRef();

  const applyFilter = (values) => {
    
    if(values.orderCreationFrom){
        setCreatedFrom = values.orderCreationFrom
    }
    if(values.orderCreationTo){
        setCreatedTo = values.orderCreationTo
    }

  };

 
  const dispatch = useDispatch();

  const executeReport=()=>{
    if(!loading) {
        setLoading(true);
        //filter={"order_delivered_from":"2022-03-03", "order_delivered_to":"2022-03-03","shipping_type":"3"}&sortOrder=desc&sortField=id&pageNumber=1&pageSize=10
        const filter ={
            "order_delivered_from" : moment(createdFrom).format('YYYY-MM-DD'),
            "order_delivered_to": moment(createdTo).format('YYYY-MM-DD'),
            "shipping_type":"3"
        }

        pharmaCrud.getPharmaDailyAccount({filter:filter}).then((response) => {
            setEntities(response.data.results)

            if(response && response.data.results.length>0){
                const datos = response.data.results.map(item => ({
                    idPedido:item.id,
                    cliente:item.customer.last_name + ', ' + item.customer.first_name,
                    comprobante:item.billing.invoice,
                    tipoPago:item.payment_type.name,
                    total:item.total
                }));
                
                setDataCSV(datos);
                
            }

            setLoading(false);

        }).catch(() => {
            setLoading(false);
        });
    }

  }

  const exportAccounts = ()=>{
        if(dataCSV.length>0) {
            csvRef.current.link.click();
        }
  }

  

  return (
    <>
      <Formik
        initialValues={{

        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            
            <div className="form-group row">

                <div className="col-lg-3">
                    <ReactDatePicker
                        autoComplete='off'
                        className="form-control"
                        style={{ width: "100%" }}
                        name="createdFrom"
                        selected={createdFrom}
                        maxDate={createdTo?(createdTo>new Date()?new Date():createdTo):new Date()}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => {
                        setCreatedFrom(date);
                        setFieldValue("order_creation_from", date?date:null);
                        handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                    Fecha <b>desde</b>
                    </small>
                </div>
              
                <div className="col-lg-3">
                    <ReactDatePicker
                        autoComplete='off'
                        className="form-control"
                        style={{ width: "100%" }}
                        name="createdTo"
                        selected={createdTo}
                        minDate={createdFrom}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) =>  {
                            setCreatedTo(date);
                            setFieldValue("order_creation_to", date?date:null);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                    Fecha <b>hasta</b>
                    </small>
                </div>



            </div>

            <div className="form-group row">
                <div className="col float-right">

                    <button type="button" onClick={executeReport} className="btn btn-light float-right m-2" > 
                        <i className="fa fa-play text-primary"></i>
                        Ejecutar
                    </button>  

                    { entities && entities.length>0 && 
                      
                        <>
                            <button type="button" onClick={exportAccounts} className="btn btn-success float-right m-2"> <i className="fa fa-file-excel"></i>
                            Exportar CSV
                            </button> 
                
                            <CSVLink
                                data={dataCSV}
                                filename={"CierreFarmacia_" + moment().format("YYYYMMDD") + ".csv"}
                                target="_blank"
                                ref={csvRef}
                            >
                            </CSVLink>


                            <ReactToPrint
                              trigger={() => <button type="button" className="btn btn-primary float-right m-2"> <i className="fa fa-print"></i>
                                              Imprimir
                                              </button>}
                              content={() => printRef.current}
                              pageStyle="print"
                            />
                            <AccountPrintPage ref={printRef} entities={entities} from={moment(createdFrom).format('DD/MM/YYYY')} to={moment(createdTo).format('DD/MM/YYYY')}/>


                        </>

                    }
                </div>  
            </div>


          </form>
        )}
      </Formik>
    </>
  );
}



