import React, {useMemo, useEffect, useState, useRef} from "react";
import { CSVLink } from "react-csv";
import * as reportsCrud from "../../_redux/reportsCrud";
import { useReportsUIContext } from "../ReportsUIContext";
import moment from "moment";


export function ArticleReportToCSV() {

    const [dataCSV, setDataCSV] = useState([]);
    const [loading, setLoading] = useState(false);
    const csvRef = useRef();
    
    const reportsUIContext = useReportsUIContext();
    const reportsUIProps = useMemo(() => {
      return {
        queryParams: reportsUIContext.actualReportQueryParams,
      };
    }, [reportsUIContext]);

    const getArticles = ()=>{
        const newQueryParams = {...reportsUIProps.queryParams}
        newQueryParams.pageSize = null;
        if(!loading) {
            setLoading(true);
            reportsCrud.getReportByName("articles", newQueryParams).then((response) => {

                const datos = response.data.results.map(item => ({
                    //id:item.id,
                    producto:item.article,
                    cantidad:item.qty,
                }));

                setDataCSV(datos);
                //setDataCSV(response.data.results);
                setLoading(false);
                csvRef.current.link.click();
            }).catch(() => {
                setLoading(false);
            });
        }
    }

    return (
        <>

            <button type="button" onClick={getArticles} className="btn btn-light float-right m-2"> <i className="fa fa-file-excel text-primary"></i>
            Exportar CSV
            </button> 

            <CSVLink
                data={dataCSV}
                filename={"ReporteProductos_" + moment().format("YYYYMMDD") + ".csv"}
                target="_blank"
                ref={csvRef}
            >
            </CSVLink>
        </>


      );
}























