import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  pageNr: 1,
}

export const pageNumberSlice = createSlice({
  name: 'pageNumber',
  initialState,
  reducers: {
    setPageNumber: (state, action) => {
      state.pageNr = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPageNumber } = pageNumberSlice.actions

export default pageNumberSlice.reducer