import React, {useMemo, useEffect, useState} from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { PharmaDeliveriesFilters } from "./pharma-deliveries-table/PharmaDeliveriesFilters";
import { PharmaDeliveriesTable } from "./pharma-deliveries-table/PharmaDeliveriesTable";
import { PharmaDeliveriesViews } from "./pharma-deliveries-table/PharmaDeliveriesViews";
import { usePharmaDeliveriesUIContext } from "./PharmaDeliveriesUIContext";
import * as deliveriesActions from "../../_redux/pharma-deliveries/pharmaDeliveriesActions";
import moment from 'moment';


const logistic_states = [
  {value:1, label:'Pendiente'},
  {value:2, label:'En Armado'},
  {value:3, label:'En Distribución'},
  {value:4, label:'Entregado'},
  {value:5, label:'Cancelado'},
  {value:6, label:'Sin Despacho'}
]

export function PharmaDeliveriesCard() {
    
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [showOrderCancelDialog, setShowOrderCancelDialog] = useState(false);
    const [showDeliveryOk, setShowDeliveryOk] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [monto, setMonto] = useState(null);
    const [paidDate, setPaidDate] = useState(null);

    const pharmaDeliveriesUIContext = usePharmaDeliveriesUIContext();
    const pharmaDeliveriesUIProps = useMemo(() => {
      return {
        selectedIds: pharmaDeliveriesUIContext.selectedIds,
        setSelectedIds: pharmaDeliveriesUIContext.setSelectedIds
      };
    }, [pharmaDeliveriesUIContext]);

    const { currentState } = useSelector(
      (state) => ({ currentState: state.pharmaDeliveries }),
      shallowEqual
    );
    const { totalCount, entities} = currentState;

    const dispatch = useDispatch();
    const setOrderDeliveryOk=(order)=>{
        setShowConfirmationDialog(true);

        setOrderId(order.id);
        setPaidDate(order.paid_date);
        setMonto(order.total);

    }

    const cancelOrders = ()=>{
      let ordersEnabledForCancel = entities.filter(o=>o.logistic_state_id!=logistic_states.filter(ls=>ls.label=='Entregado')[0].value)

      let ordersForCancel = ordersEnabledForCancel.filter(o=>pharmaDeliveriesUIProps.selectedIds.includes(o.id))

      console.log(ordersForCancel.map(o=>o.id).join(","))
      setShowOrderCancelDialog(false);
    }

    const orderConfirmation=()=>{
        const obTransfer ={
          delivered:[orderId],
          returned:[],
          pending:[]
        }

        dispatch(deliveriesActions.confirmDeliveries(obTransfer)).then(()=>{
            setShowDeliveryOk(true)
            setTimeout(() => {
              setShowDeliveryOk(false);
            }, 2000);
        })
        setShowConfirmationDialog(false);
    }

    return (
      <Card>

        { showDeliveryOk && <Alert variant="success" onClose={()=>setShowDeliveryOk(false)} dismissible> Orden entregada correctamente</Alert> }


        <CardHeader title="Entregas Farmacia">

          <CardHeaderToolbar>
            <PharmaDeliveriesViews />
          </CardHeaderToolbar>

        </CardHeader>
        
        <CardBody>

        <Modal size="md" show={showConfirmationDialog}  backdrop='static' >
            <Modal.Header closeButton>
              <Modal.Title>Confirmación</Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay overlay-block cursor-default">
              <p>Confirma la entrega de la órden <b>{orderId}</b> por un monto de <b>${monto}</b> ? </p>
              <br/>
              { paidDate && <p>La órden fue pagada el {moment(paidDate).format("DD/MM/YYYY")} a las {moment(paidDate).format("HH:mm")}</p>}
              { !paidDate && <>
                              <p>La órden <b className="text-danger">NO</b> está paga</p>
                              <p>Al confirmar la entrega se asume como pagada.</p>
                            </>
              }
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setShowConfirmationDialog(false)}>Cancelar</Button>
              <Button variant="primary" onClick={()=>orderConfirmation()}>Confirmar Entrega</Button>
            </Modal.Footer>
        </Modal>

        {/* <Modal size="md" show={showOrderCancelDialog}  backdrop='static' >
            <Modal.Header closeButton>
              <Modal.Title>Confirmación</Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay overlay-block cursor-default">
              <p>Confirma la cancelación de {pharmaDeliveriesUIProps.selectedIds.length} órdenes ? </p>
              <p>Solo se cancelarán las órdenes que <b>no</b> estén <b>Entregadas</b></p>
              <br/>

            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setShowOrderCancelDialog(false)}>Volver</Button>
              <Button variant="primary" onClick={cancelOrders}>Cancelar Ordenes</Button>
            </Modal.Footer>
        </Modal> */}


        <PharmaDeliveriesFilters />


        {/* <DropdownButton id="dropdown-basic-button" title="Acciones">

            <span className="d-block text-center text-primary">{pharmaDeliveriesUIProps.selectedIds.length?pharmaDeliveriesUIProps.selectedIds.length + " órdenes": totalCount + " órdenes"}</span>

            <Dropdown.Item onClick={() => setShowOrderCancelDialog(true)} disabled={pharmaDeliveriesUIProps.selectedIds.length<=0}>Cancelar Ordenes</Dropdown.Item>

        </DropdownButton> */}


        <PharmaDeliveriesTable  setOrderDeliveryOk={setOrderDeliveryOk}/>

        </CardBody>
      
      </Card>
    );
}
