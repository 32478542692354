import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { InventoryProvider } from "../InventoryContext";
import { AlfabetaEditCard } from "./alfabeta/alfabeta-edit/AlfabetaEditCard";
import { AlfabetaListCard } from "./alfabeta/alfabeta-list/AlfabetaListCard";
import { ProductsListCard } from "./products/product-list/ProductsListCard";
import { ProductEditCard } from "./products/product-edit/ProductEditCard";
import { AlfabetaHistoricoCard } from "./alfabeta/AlfabetaHistoricoCard";
import { LaboratoryListCard } from "./laboratories/laboratory-list/LaboratoryListCard";
import { LaboratoryEditCard } from "./laboratories/laboratory-edit/LaboratoryEditCard";

export default function InventoryPage() {
  return (

    <Suspense fallback={<LayoutSplashScreen />}>
      <InventoryProvider>
        <Switch>
          {
            /* Redirect from eCommerce root URL to /orders */
            <Redirect
              exact={true}
              from="/inventario"
              to="/inventario/productos"
            />
          }
          <ContentRoute path="/inventario/productos/:id" component={ProductEditCard} />
          <ContentRoute path="/inventario/productos" component={ProductsListCard} />
          <ContentRoute path="/inventario/alfabeta/:id/historico" component={AlfabetaHistoricoCard} />
          <ContentRoute path="/inventario/alfabeta/:id" component={AlfabetaEditCard} />
          <ContentRoute path="/inventario/alfabeta" component={AlfabetaListCard} />
          <ContentRoute path="/inventario/laboratorios/:id" component={LaboratoryEditCard} />
          <ContentRoute path="/inventario/laboratorios" component={LaboratoryListCard} />



        </Switch>
      </InventoryProvider>
    </Suspense>
  );
}
