// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider,  } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/pharma-deliveries/pharmaDeliveriesActions";
import * as printActions from "../../../../Sales/_redux/print/printActions";
import * as ordersCrud from "../../../../Sales/_redux/orders/ordersCrud";
import ReactToPrint, { useReactToPrint } from "react-to-print";

import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { usePharmaDeliveriesUIContext } from "../PharmaDeliveriesUIContext";
import * as uiHelpers from "../PharmaDeliveriesUIHelpers";
import { Badge, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { OrderPrintPage } from "../OrderPrintPage";
import { CancelOrderDialog } from "../../../../Sales/pages/orders/order-edit/CancelOrderDialog";

const capitalizeWords = (name)=>{
  const words = name.toLowerCase().split(" ");

  return words.map((word) => { 
      return word[0].toUpperCase() + word.substring(1); 
  }).join(" ");

  
}



export function PharmaDeliveriesTable({setOrderDeliveryOk}) {

  const printOrderRef = useRef();
  const [orderToPrint, setOrderToPrint] = useState(null);
  const [orderToCancel, setOrderToCancel] = useState(null);
  const [showCancelOrderConfirmation, setShowCancelOrderConfirmation] = useState(false);
    
  const pharmaDeliveriesUIContext = usePharmaDeliveriesUIContext();
  const pharmaDeliveriesUIProps = useMemo(() => {
    return {
      queryParams: pharmaDeliveriesUIContext.ordersQueryParams,
      setQueryParams: pharmaDeliveriesUIContext.setOrdersQueryParams,
      openEditOrderPage: pharmaDeliveriesUIContext.openEditOrderPage,
      selectedIds: pharmaDeliveriesUIContext.selectedIds,
      setSelectedIds: pharmaDeliveriesUIContext.setSelectedIds
    };
  }, [pharmaDeliveriesUIContext]);

  // Getting curret state of orders list from store (Redux)
  const { reportState } = useSelector(
    (state) => ({ reportState: state.pharmaDeliveries }),
    shallowEqual
  );
  const { totalCount, entities, listLoading, ordersUpdated:reportUpdated } = reportState;

  const { orderState } = useSelector(
    (state) => ({ orderState: state.orders }),
    shallowEqual
  );
  const { ordersUpdated:orderUpdated } = orderState;


  // orders Redux state
  const dispatch = useDispatch();

  const deliver=(order)=>{
      setOrderDeliveryOk(order)
  }
  
  const cancel=(order)=>{
    setShowCancelOrderConfirmation(true);
    setOrderToCancel(order);
    
  }

  const handlePrint = useReactToPrint({
    content: () => printOrderRef.current,
  });

  const printGuide=(order)=>{
      //dispatch(printActions.printGuideFromPharmacy(order.id))  
      ordersCrud.getOrderById(order.id).then(response=>{
        setOrderToPrint(response.data.results)
        handlePrint();
      }) 
  }

  useEffect(() => {
    dispatch(actions.fetchOrders(pharmaDeliveriesUIProps.queryParams));
  }, [ pharmaDeliveriesUIProps.queryParams, reportUpdated, orderUpdated]);


  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.IdColumnFormatter,
      formatExtraData: {
        openEditOrderPage: pharmaDeliveriesUIProps.openEditOrderPage
      },
    },
    {
      dataField: "customer",
      text: "Cliente",
      //filter: textFilter(),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <span style={{  }}>
          <p style={{ marginBottom:'0' }}>{ capitalizeWords(cellContent.first_name) }</p>
          <p style={{ marginBottom:'0' }}>{ capitalizeWords(cellContent.last_name) }</p>
          { (row.billing.last_name.toLowerCase().trim()!=cellContent.last_name.toLowerCase().trim() && row.billing.first_name.toLowerCase().trim()!=cellContent.first_name.toLowerCase().trim())? 
            <p style={{ marginBottom:'0' }}>
              (Fc: { capitalizeWords(row.billing.first_name) } { capitalizeWords(row.billing.last_name) })
            </p>: ""
          }
        </span>
      )
    },
    // {
    //   dataField: "billing",
    //   text: "Facturado a",
    //   //filter: textFilter(),
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: (cellContent, row) => (
    //     <span style={{  }}>
    //       <p style={{ marginBottom:'0' }}>{ capitalizeWords(cellContent.last_name) }</p>
    //       <p style={{ marginBottom:'0' }}>{ capitalizeWords(cellContent.first_name) }</p>
    //     </span>
    //   )
    // },
    {
      dataField: "billing.tax.number",
      text: "Documento",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "source.name",
      text: "Origen",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.SourceColumnFormatter
    },

    {
      dataField: "order_creation",
      text: "Fecha",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DateColumnFormatter
    },
    {
      dataField: "shipping.type.name",
      text: "Envío",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.ShippingTypeColumnFormatter
    },
    {
      dataField: "paid_date",
      text: "",
      sort: false,
      formatter: (cell)=>{
        return (<> { cell && 
            <OverlayTrigger overlay={<Tooltip id="orders-delete-tooltip">Pagada</Tooltip>} >
              <span><i className={"text-success fa fa-dollar-sign"}></i> </span> 
            </OverlayTrigger>            
          } </> )
      },
      headerFormatter: ()=> {
            return (
                <span><i className={"fa fa-credit-card"}></i> </span>
            )
      }
    },
    {
      dataField: "shipping.note",
      text: "",
      sort: false,
      //isDummyField: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.NotesColumnFormatter,
      headerFormatter: ()=> {
            return (
                <span><i className={"far fa-comment"}></i> </span>
            )
        }
    },
    {
      dataField: "delivered_date",
      text: "",
      sort: false,
      formatter: (cellContent)=>{
        return (<> { cellContent && 
          <OverlayTrigger overlay={<Tooltip id="orders-delete-tooltip">Entregada</Tooltip>} >
            <span><i className={"text-success fa fa-check"}></i> </span> 
          </OverlayTrigger>            
        } </> )

      },
      headerFormatter: ()=> {
            return (
                <span><i className={"far fa-handshake"}></i> </span>
            )
        }
    },
    {
      dataField: "entregar",
      text: "",
      dummyField: true,
      headerClasses: "text-center",
      classes: "text-center",
      formatter: (cell, row)=>{
        
        return (
          <>
         {!row.delivered_date &&
            <>
                  <OverlayTrigger overlay={<Tooltip id="waves-print-tooltip">Imprimir Orden</Tooltip>} >
                    <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1" onClick={() => {printGuide(row)}} > <span><i className="fa fa-print text-info"></i></span> </a>
                  </OverlayTrigger>

              {/* <ComponentToPrintWrapper row={row} ></ComponentToPrintWrapper> */}
 
                <OverlayTrigger overlay={<Tooltip id="waves-print-tooltip">Entregar</Tooltip>} >
                  <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1" onClick={() => {deliver(row)}} > <span><i className="fa fa-clipboard-check text-primary"></i></span> </a>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="waves-print-tooltip">Cancelar Orden</Tooltip>} >
                  <a className="btn btn-icon btn-light btn-hover-danger btn-sm mx-1" onClick={() => {cancel(row)}} > <span><i className="fa fa-trash text-danger"></i></span> </a>
                </OverlayTrigger>
            </>

          }
          
          </>
      )
      },
    },

  ];
  

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: pharmaDeliveriesUIProps.queryParams.pageSize,
    page: pharmaDeliveriesUIProps.queryParams.pageNumber,
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Mostrando { from } a { to } de { size } Resultados
      </span>
    )
  };
  
  // const selectRow = {
  //   mode: 'checkbox',
  //   clickToSelect: false,
  //   selectColumnPosition: 'left',
  //   hideSelectAll: true,
  //   bgColor: '#C9F7F5',
  //   selected:pharmaDeliveriesUIProps.selectedIds,
  //   onSelect: (row, isSelect, rowIndex, e) => {
  //       if (true) {
  //           if(isSelect){
  //             pharmaDeliveriesUIProps.setSelectedIds([...pharmaDeliveriesUIProps.selectedIds, row.id]);
  //           }
  //           else{
  //             pharmaDeliveriesUIProps.setSelectedIds(pharmaDeliveriesUIProps.selectedIds.filter(item=>item!=row.id))
  //           }
  //       }
  //     },
  // };



  return (
    <>

      {orderToPrint && <OrderPrintPage ref={printOrderRef} order={orderToPrint}/>}

      <CancelOrderDialog show={showCancelOrderConfirmation} onHide={()=>setShowCancelOrderConfirmation(false)} order={orderToCancel}/>

      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (

            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >

              <BootstrapTable
                rowStyle={ { fontSize: "smaller" } }
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                condensed={false}
                // striped={true}
                remote //</Pagination>= { { filter: true } }
                //filter={ filterFactory() }
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                // selectRow={ selectRow } 
                onTableChange={ getHandlerTableChange( pharmaDeliveriesUIProps.setQueryParams) }
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>

            </Pagination>

          );
        }}
      </PaginationProvider>
    </>
  );
}
