import {createSlice} from "@reduxjs/toolkit";

const initialReportsState = {
  listLoading: false,
  totalCount: 0,
  entities: null,
  report:{
    entities:null,
    totalCount:0
  },
  reportUpdated: false,
  lastError: null
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState: initialReportsState,
  reducers: {
    
    reset: () => initialReportsState,

    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getReportById or byName
    
    reportFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.report.entities = entities;
      state.report.totalCount = totalCount;
      state.error = null;
    },
    // findReports
    
    reportsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // reportUpdated
    reportUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.report.id) {
          return action.payload.report;
        }
        return entity;
      });
    },
  }
});
