import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import * as alfabetaCrud from '../../../_redux/alfabetaCrud'
import "../../../inventory.css";

export function LaboratoryEditCard({history}) {
    const { id } = useParams();
    const [medicamento, setMedicamento] = useState({});

    const fetchData = async()=>{
        const res = await alfabetaCrud.getProductById({id})
        if(res && res.data && res.data.results){
            setMedicamento(res.data.results[0])
        }
    }
    
    const viewHistoricalData = (reg)=>{
        history.push(`/inventario/alfabeta/${reg}/historico`);
    }

    useEffect(()=>{
        fetchData();
    }, [])
  
    return (
        <Card>
        <CardHeader title={medicamento.nom || ""}>
            <CardHeaderToolbar>
                <div className="text-right">
                    <button type="button" className="btn btn-success" onClick={()=>{history.goBack()}} >
                        <i className="fa fa-chevron-left"></i> Volver 
                    </button>
                    <button type="button" className="btn btn-primary ml-2" onClick={()=>{viewHistoricalData(medicamento.reg)}} >
                        <i className="fa fa-dollar-sign"></i> Histórico 
                    </button>
                </div>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
            
            <Row>
                <Col>
                    <div className="form-group">
                        <label><b>Nombre Comercial</b></label>
                        <Form.Control type="text"  disabled name="note" value={medicamento.nom || ""}  className="form-control" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label><b>Presentación</b></label>
                        <Form.Control type="text"  disabled name="note" value={medicamento.pres || ""}  className="form-control" />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label><b>Potencia</b></label>
                        <Form.Control type="text"  disabled name="note" value={medicamento.pot?(medicamento.pot.valor + ' ' + medicamento.pot.unidad):""}  className="form-control" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label><b>Forma</b></label>
                        <Form.Control type="text"  disabled name="note" value={medicamento?.form?.des || ""} className="form-control" />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label><b>Heladera</b></label>
                        <Form.Control type="text"  disabled name="note" value={medicamento.hel || ""} className="form-control" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label><b>Laboratorio</b></label>
                        <Form.Control type="text"  disabled name="note" value={medicamento.lab? medicamento.lab.name : ""} className="form-control" />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label><b>Monodroga</b></label>
                        <Form.Control type="text"  disabled name="note" value={medicamento.monodroga || ""} className="form-control" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label><b>Acción terapéutica</b></label>
                        <Form.Control type="text"  disabled name="note" value={medicamento.accion? medicamento.accion.des : ""} className="form-control" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label><b>Código de barras</b></label>
                        <Form.Control type="text"  disabled name="note" value={medicamento.bars || ""} className="form-control" />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label><b>GTIN</b></label>
                        <Form.Control type="text"  disabled name="note" value={medicamento.gtins || ""} className="form-control" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label><b>Cód. Alfabeta</b></label>
                        <Form.Control type="text"  disabled name="note" value={medicamento.reg || ""} className="form-control" />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label><b>Precio</b></label>
                        <Form.Control type="text"  disabled name="note" value={medicamento.precio?'$'+medicamento.precio : ""} className="form-control" />
                    </div>
                </Col>
            </Row>


        </CardBody>
        </Card>
    );
}

