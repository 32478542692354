import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { useInventoryContext } from "../../../InventoryContext";
import { AlfabetaFilters } from "./AlfabetaFilters";
import { AlfabetaList } from "./AlfabetaList";
import * as XLSX from 'xlsx/xlsx.mjs';
import * as alfabetaCrud from '../../../_redux/alfabetaCrud'
import { errorMsg, successMsg } from "../../../../Global/helpers";
import moment from "moment";

const initialFilter = {
  filter: {    

  },
  sortOrder: "asc", 
  sortField: "reg",
  pageNumber: 1,
  pageSize: 10
};

export function AlfabetaListCard({ history }) {
  const [queryParams, setQueryParams] = useState(initialFilter);
  const [search, setSearch] = useState(false);
  const [lastProcessDate, setLastProcessDate] = useState("");

  const inventoryContext = useInventoryContext();
  const{ selectedIds, setSelectedIds, totalIds, totalCountAB, favoritesCount, loading, setLoading, entitiesAB, setEntitiesAB} = inventoryContext;

  const viewProduct = (row)=>{
      history.push(`/inventario/alfabeta/${row.reg}`);
  }
  const viewHistoricalData = (reg)=>{
      history.push(`/inventario/alfabeta/${reg}/historico`);
  }

  const { user } = useSelector(
    (state) => ({ user: state.auth.user }),
    shallowEqual
  );
  
  const fetchProductsExcel = async()=>{
    const _products = await alfabetaCrud.getProductsAll({...queryParams, pageSize:null, sortField:"nom"});
    if(_products && _products.data && _products.data.results){
        if(selectedIds.length>0){
          return _products.data.results.filter(d=>selectedIds.includes(d.reg));
        }
        else{
            return _products.data.results;
        }        
    }
  }

  const fetchLastProcessDate = async ()=>{
      const _date = await alfabetaCrud.getLastProcessDate();
      if(_date.data && _date.data.fecha_ultimo_proceso){
        setLastProcessDate(moment(_date.data.fecha_ultimo_proceso).format('YYYY/MM/DD HH:mm'))

      }
  }

  const handleExport = async ()=>{
    try{

        if(!loading) {
            setLoading(true);
            let datos = await fetchProductsExcel();

            let muestraFecha1 = datos.filter(i=>i.precio_fecha1.valor!=null)
            let muestraFecha2 = datos.filter(i=>i.precio_fecha2.valor!=null)              
            let promedio = datos.filter(i=>i.promedio_dias!=null)

            datos = datos.map(d=>{
                let data={
                    nro_alfabeta:d.reg,
                    nombre:d.nom,
                    monodroga:d.monodroga,
                    presentacion:d.pres,
                    laboratorio:d.lab.name,
                    accion_terapeutica:d.accion.des,
                    precio_actual:d.precio?parseFloat(d.precio).toFixed(2):'',
                    dias_vigencia:d.precio_actual.dias,
                    ultima_variacion:d.precio_anterior.variacion?d.precio_anterior.variacion.toFixed(2) + '%' : '',
                    gtin:d.gtins,
                    bar:d.bars,
                    heladera:d.hel,
                    notifica_cambio_precio: (d.notificar_cambios && d.notificar_cambios==true)?'S':'N'
                };
                if(promedio.length>0){
                    data={...data, 
                      prom_dias_cambio_precio: d.promedio_dias?parseInt(d.promedio_dias):""
                    }
                }
              
                if(muestraFecha1.length>0){
                    data={...data, 
                      fecha1: d.precio_fecha1.f_inicio ? moment(d.precio_fecha1.f_inicio).format("DD/MM/YYYY"):"",
                      precio_fecha1: d.precio_fecha1.valor?parseFloat(d.precio_fecha1.valor).toFixed(2) : ''
                    }
                }
                
                if(muestraFecha2.length>0){
                    data={...data, 
                      fecha2: d.precio_fecha2.f_inicio ? moment(d.precio_fecha2.f_inicio).format("DD/MM/YYYY"):"",
                      precio_fecha2: d.precio_fecha2.valor?parseFloat(d.precio_fecha2.valor).toFixed(2) : '',
                      variacion_fecha1_fecha2:d.precio_fecha2.variacion?parseFloat(d.precio_fecha2.variacion).toFixed(2) + '%' : ''
                    }
                }

                return data
            })

            var ws = XLSX.utils.json_to_sheet(datos);

            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Inventario");
            XLSX.writeFile(wb, "ListadoAlfabeta.xlsx");
            setLoading(false);
        }
    }
    catch(err){
        setLoading(false);
    }
  }

  const addToFavorites = async () => {
    
      Swal.fire({
        title: 'Confirmación',
        text: `Confirma pasar ${selectedIds.length>0?selectedIds.length:totalCountAB - favoritesCount} productos a la lista de favoritos?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        confirmButtonColor: '#0A9E95',
        cancelButtonText:'No'
        }).then(async res=>{
            if (res.isConfirmed) {
                try{
                    if(selectedIds.length){
                      await alfabetaCrud.notificationONMany({ids:selectedIds});
                      const newEntities = entitiesAB.map(e=>{
                          if(selectedIds.includes(e.reg)){
                            return {...e, notificar_cambios:1}
                          }
                          return e;
                      })
                      setEntitiesAB(newEntities);
                      setSelectedIds([]);
                    }
                    else{
                      await alfabetaCrud.notificationONMany({ids:totalIds});
                      const newEntities = entitiesAB.map(e=>{
                          if(totalIds.includes(e.reg)){
                            return {...e, notificar_cambios:1}
                          }
                          return e;
                      })
                      setEntitiesAB(newEntities);
                    }

                    successMsg(`Artículos agregados a favoritos correctamente`);
  
                }
                catch(err){
                    errorMsg("Error actualizando los favoritos")
                }
            } 
        })
  }

  const removeFromFavorites = ()=>{
    
      Swal.fire({
          title: 'Confirmación',
          text: `Confirma quitar ${selectedIds.length>0?selectedIds.length:favoritesCount} productos de la lista de favoritos?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí',
          confirmButtonColor: '#0A9E95',
          cancelButtonText:'No'
      }).then(async res=>{
          if (res.isConfirmed) {
              try{
                  if(selectedIds.length){
                    await alfabetaCrud.notificationOFFMany({ids:selectedIds});
                    const newEntities = entitiesAB.map(e=>{
                        if(selectedIds.includes(e.reg)){
                          return {...e, notificar_cambios:0}
                        }
                        return e;
                    })
                    setEntitiesAB(newEntities);
                    setSelectedIds([]);
                  }
                  else{
                    await alfabetaCrud.notificationOFFMany({ids:totalIds});
                    const newEntities = entitiesAB.map(e=>{
                        if(totalIds.includes(e.reg)){
                          return {...e, notificar_cambios:0}
                        }
                        return e;
                    })
                    setEntitiesAB(newEntities);
                  }

                  successMsg(`Artículos agregados a favoritos correctamente`);

              }
              catch(err){
                  errorMsg("Error actualizando los favoritos")
              }
          } 
      })
  }

  useEffect(()=>{
    fetchLastProcessDate();
  }, [])

  return (
    <Card>
      <Card.Header style={{paddingBottom:"0"}}>
          <Row>
              <Col>
                <div className="d-flex">
                  <h3 className="m-2">Medicamentos Alfabeta</h3>
                  {loading && (
                      <div className="overlay-layer bg-transparent mt-5 ml-5">
                        <div className="spinner spinner-lg spinner-primary" />
                      </div>
                  )}
                </div>
                <div className="ml-2">
                  <p style={{color:"#656565", textAlign:"end"}}>Última actualización: {lastProcessDate}</p>
                </div>
              </Col>
          </Row>
        

      </Card.Header>
      <Card.Body>

        
        <AlfabetaFilters setQueryParams={setQueryParams}  queryParams={queryParams} seacrh={search} setSearch={setSearch} lastProcessDate={lastProcessDate}/>

        { (user.role.name=='ADMIN' || user.role.name=='INVENTARIO')  && totalCountAB>0 &&
              <DropdownButton id="dropdown-basic-button" title="Acciones">

                  <span className="d-block text-center text-primary">{selectedIds.length>0?selectedIds.length + " registros": totalCountAB + " registros"}</span>

                  { (totalCountAB - favoritesCount)>0 && <Dropdown.Item onClick={() => addToFavorites()}>Agregar a Favoritos</Dropdown.Item>}

                  { (totalCountAB == favoritesCount) &&  favoritesCount>0 && <Dropdown.Item onClick={() => removeFromFavorites()}>Quitar de Favoritos</Dropdown.Item>}

                  { totalCountAB>0 && <Dropdown.Item onClick={() => handleExport()} >Exportar a Excel</Dropdown.Item>}
                  
              </DropdownButton>
        }


        <AlfabetaList viewProduct={viewProduct} viewHistoricalData={viewHistoricalData} queryParams={queryParams} setQueryParams={setQueryParams} search={search} setSearch={setSearch}/>

      </Card.Body>
    </Card>
  );
}
