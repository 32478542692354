import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";

const numberFormat = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' })


export function PreciosDialog({show, onHide, medicamento}) {
    const [entities, setEntities] =useState([]);

    useEffect(()=>{
        if(medicamento && medicamento.precios){
            setEntities(medicamento.precios.map((p, idx)=>({...p, idx})))
        }
    }, [medicamento])

    const columns = [
        {
          dataField: "f_inicio",
          text: "Fecha Desde",
          formatter: (cellContent, row) => (
            <span>
              <p style={{ marginBottom:'0' }}>{ cellContent?moment(cellContent).format("DD/MM/YYYY"):"" }</p>
            </span>
            )
        },
        {
          dataField: "f_fin",
          text: "Fecha Hasta",
          formatter: (cellContent, row) => (
            <span>
              <p style={{ marginBottom:'0' }}>{ cellContent?moment(cellContent).format("DD/MM/YYYY"):"" }</p>
            </span>
            )
        },
        {
          dataField: "prc",
          text: "Precio",
          formatter: (cellContent, row) => (
            <span>
              <p style={{ marginBottom:'0'}}><b>{ cellContent? numberFormat.format(cellContent):"" }</b></p>
            </span>
            )
        },
      ];
    return (
      <>

        <Modal show={show} onHide={onHide}  backdrop='static' size="lg">
            <Modal.Header>
                <Modal.Title >{medicamento ? medicamento.nom + ' - Evolución de precios' : '' }</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">

                <BootstrapTable
                        wrapperClasses="table-responsive"
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        bordered={false}
                        //striped={true}
                        remote
                        keyField="idx"
                        data={ entities || []}
                        columns={columns}
                    >
                </BootstrapTable>
            </Modal.Body>
            <Modal.Footer>

            <button type="button" onClick={onHide} className="btn btn-light btn-elevate" >
                Cerrar
            </button>

            </Modal.Footer>
        </Modal>

      </>
    );
}
