import {createSlice} from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";

//const history = useHistory();

const initialWavesState = {
  error: false,
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  waveForEdit: undefined,
  lastError: null,
  waveUpdated: false,
  newWave: false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const wavesSlice = createSlice({
  name: "waves",
  initialState: initialWavesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.newWave = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getWaveById
    waveFetched: (state, action) => {
      state.actionsLoading = false;
      state.waveForEdit = action.payload.waveForEdit;
      state.error = null;
    },
    // findWaves
    wavesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.newWave = false;
    },
    // createWave
    waveCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      //state.entities.push(action.payload.wave);
      state.newWave = true;
      state.waveUpdated = !state.waveUpdated;
    },
    // updateWave
    waveUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.wave.id) {
          return action.payload.wave;
        }
        return entity;
      });
    },
    // deleteWave
    waveDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteWaves
    wavesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // wavesUpdateState
    wavesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },

   
    // createOrderTx
    orderTxCreated: (state, action) => {
      state.waveUpdated = !state.waveUpdated;
      state.actionsLoading = false;
      state.error = null;
      
    },
    
    // createOrderShipp
    orderShippCreated: (state, action) => {
      state.waveUpdated = !state.waveUpdated;
      state.actionsLoading = false;
      state.error = null;
      
    },

    // createOrderTx
    txRollbacked: (state, action) => {
      state.waveUpdated = !state.waveUpdated;
      state.error = null;
      
    },
    
    waveReady: (state, action) => {
      state.waveUpdated = !state.waveUpdated;
      state.error = null;
      
    },
    
    deleteReady: (state, action) => {
      state.waveUpdated = !state.waveUpdated;
      state.error = null;
      
    },

    orderRemoved: (state, action) => {
      state.waveUpdated = !state.waveUpdated;
      state.error = null;
      
    },
    
    
  }
});
