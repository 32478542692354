import React, {useMemo, useState, useEffect} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { ExportToCSV } from "../../Sales/pages/orders/order-list/ExportToCSV";

import { ReportsTable } from "./reports-table/ReportsTable";

export function ReportsCard() {


    return (
        <Card>
        <CardHeader title="Reportes">

            <CardHeaderToolbar>
            
            </CardHeaderToolbar>

        </CardHeader>
        <CardBody>
            
            <ReportsTable />

        </CardBody>
        </Card>
    );
}
