// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";

import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useReportsUIContext } from "../ReportsUIContext";

export function ReportsTable(props) {
    const reportsUIContext = useReportsUIContext();
    const reportsUIProps = useMemo(() => {
      return {
        ids: reportsUIContext.ids,
        queryParams: reportsUIContext.queryParams,
        setQueryParams: reportsUIContext.setQueryParams,
        executeButtonClick: reportsUIContext.executeButtonClick,
      };
    }, [reportsUIContext]);

    const entities=[
      {
        id:1,
        name:"Despachos",
        description:"Control de gestión y despachos"
      }, 
      {
        id:2,
        name:"Despachos - Artículos",
        description:"Consolidado de productos por fecha"
      }, 
      {
        id:3,
        name:"Órdenes",
        description:"Características y datos de las órdenes"
      }, 
      {
        id:4,
        name:"Órdenes - Artículos",
        description:"Consolidado de productos"
      }
  ];



    // Table columns
    const columns = [
        {
          dataField: "id",
          text: "ID",
          sort: true,
          sortCaret: sortCaret,
        },
        {
          dataField: "name",
          text: "Reporte",
          //filter: textFilter(),
          sort: true,
          sortCaret: sortCaret,
        },
        {
          dataField: "description",
          text: "Descripción",
          sort: true,
          sortCaret: sortCaret,
        },
        {
          dataField: "ejecutar",
          text: "",
          dummyField: true,
          formatter: (cell, row)=>{
              return (
              <OverlayTrigger overlay={<Tooltip id="waves-print-tooltip">Ir al reporte</Tooltip>} >
                  <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1" onClick={() => reportsUIProps.executeButtonClick(row.id)} > <span><i className="fas fa-play text-primary"></i></span> </a>
              </OverlayTrigger>
          )
          },
        },

    ];
  


    return (
        <>


                  <BootstrapTable
                    // rowStyle={ { fontSize: "smaller" } }
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    condensed={true}
                    remote //</Pagination>= { { filter: true } }
                    keyField="id"
                    data={entities === null ? [] : entities}
                    columns={columns}
                    onTableChange={ getHandlerTableChange( reportsUIProps.setQueryParams) }
                  >
                    <PleaseWaitMessage entities={entities} />
                    <NoRecordsFoundMessage entities={entities} />
                  </BootstrapTable>


    </>
  );
}
