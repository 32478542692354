
import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { usePharmaDeliveriesUIContext } from "../PharmaDeliveriesUIContext";


const prepareFilter = (queryParams, values) => {
    const { searchText, searchIdText, searchInvoice } = values;
    
    const newQueryParams = { ...queryParams, filter:{...queryParams.filter} };

    newQueryParams.filter.shipping_type = "3";//Retiro en Farmacia


    // Filter by order_id
    if (searchIdText) {
      newQueryParams.filter.id = searchIdText;
    }
    else{
      newQueryParams.filter.id = undefined;
    }
    
    // Filter by invoice
    if (searchInvoice) {
      newQueryParams.filter.invoice = searchInvoice;
    }
    else{
      newQueryParams.filter.invoice = undefined;
    }

    // Filter by all fields
    if (searchText) {
      newQueryParams.filter.client_name = searchText;
      //filter.client_tax_number = searchText;
    }
    else{
      newQueryParams.filter.client_name = undefined;
    }

    return newQueryParams;
};

export function PharmaDeliveriesFilters() {

  const pharmaDeliveriesUIContext = usePharmaDeliveriesUIContext();
  const pharmaDeliveriesUIProps = useMemo(() => {
    return {
      queryParams: pharmaDeliveriesUIContext.ordersQueryParams,
      setQueryParams: pharmaDeliveriesUIContext.setOrdersQueryParams,
      defaultView: pharmaDeliveriesUIContext.defaultView,
      setDefaultView: pharmaDeliveriesUIContext.setDefaultView,
    };
  }, [pharmaDeliveriesUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(pharmaDeliveriesUIProps.queryParams, values);

    if (!isEqual(newQueryParams, pharmaDeliveriesUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      pharmaDeliveriesUIProps.setQueryParams(newQueryParams);
    }
  };


  return (
    <>
      <Formik
        initialValues={{
          searchIdText: "", 
          searchOriginIdText: "", 
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">

              <div className="col-lg-3">
                <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                  <small className="form-text text-muted">
                    Buscar Cliente por <b>Nombre o DNI</b>
                </small>
              </div>
              
              <div className="col-lg-3">
                <input
                    type="text"
                    className="form-control"
                    name="searchIdText"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.searchIdText}
                    onChange={(e) => {
                      setFieldValue("searchIdText", e.target.value);
                      handleSubmit();
                    }}
                  />
                  <small className="form-text text-muted">
                    Filtro por <b>ID</b>
                </small>
              </div>
              
              <div className="col-lg-3">
              <input
                  type="text"
                  className="form-control"
                  name="searchInvoice"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchInvoice?values.searchInvoice:""}
                  onChange={(e) => {
                    setFieldValue("searchInvoice", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  Filtro por <b>Nro. Factura</b>
                </small>
              </div>
              
              <div className="col-lg-3">

              </div>


            </div>


          </form>
        )}
      </Formik>
    </>
  );
}



