import {createSlice} from "@reduxjs/toolkit";

const initialOrdersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  orderForEdit: undefined,
  ordersUpdated: false,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const pharmaDeliveriesSlice = createSlice({
  name: "pharmaDeliveries",
  initialState: initialOrdersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getOrderById
    orderFetched: (state, action) => {
      state.actionsLoading = false;
      state.orderForEdit = action.payload.orderForEdit;
      state.error = null;
    },
    // findOrders
    ordersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // updateOrder
    orderUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.order.id) {
          return action.payload.order;
        }
        return entity;
      });
    },

    orderPaid: (state, action) => {
      state.actionsLoading = false;
      state.ordersUpdated = !state.ordersUpdated;
      state.error = null;
    },
    deliveryConfirmed: (state, action) => {
      state.actionsLoading = false;
      state.ordersUpdated = !state.ordersUpdated;
      state.error = null;
    },    
  }
});
