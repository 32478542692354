import React, { createContext, useContext, useState, useCallback, useSelector } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ReportsUIHelpers";

const ReportsUIContext = createContext();

export function useReportsUIContext() {
  return useContext(ReportsUIContext);
}

export const ReportsUIConsumer = ReportsUIContext.Consumer;

export function ReportsUIProvider({ reportsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [actualReportQueryParams, setActualReportQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [withDetails, setWithDetails]=useState(false);
  
  const setQueryParams = useCallback((nextQueryParams) => {

    setQueryParamsBase((prevQueryParams) => {

      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const setActualReportQueryParams = useCallback((nextQueryParams) => {

    setActualReportQueryParamsBase((prevQueryParams) => {

      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);


  const value = {
    actualReportQueryParams,
    setActualReportQueryParams,
    queryParams,
    setQueryParams,
    setQueryParamsBase,
    ids,
    setIds,
    withDetails,
    setWithDetails,
    executeButtonClick: reportsUIEvents.executeButtonClick
  };

  return (
    <ReportsUIContext.Provider value={value}>
      {children}
    </ReportsUIContext.Provider>
  );
}



