import React from "react";
import { Route, Switch } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import { ArticleReportCard } from "./articles-report/ArticleReportCard";
import { OrderArticleReportCard } from "./order-articles-report/OrderArticleReportCard";
import { OrderReportCard } from "./order-report/OrderReportCard";

import { ReportsCard } from "./ReportsCard";
import { ReportsUIProvider } from "./ReportsUIContext";
import { WaybillReportCard } from "./waybill-report/WaybillReportCard";

export default function ReportsPage({history}) {

  const reportsUIEvents = {
  
    executeButtonClick: (id) => {
      history.push(`/reportes/${id}`);
    }, 
  

  };  

  return (

    <ReportsUIProvider reportsUIEvents={reportsUIEvents}>
        <Switch>

          {/* <ContentRoute path="/reportes/1" component={WaybillReportCard} />  */}

          <Route path="/reportes/1">
            {({history}) => (
              <WaybillReportCard history={history}/>
            )}
          </Route>
          <Route path="/reportes/2">
            {({history}) => (
              <ArticleReportCard history={history}/>
            )}
          </Route>
          <Route path="/reportes/3">
            {({history}) => (
              <OrderReportCard history={history}/>
            )}
          </Route>
          <Route path="/reportes/4">
            {({history}) => (
              <OrderArticleReportCard history={history}/>
            )}
          </Route>

          <ContentRoute component={ReportsCard} />

      </Switch>
    </ReportsUIProvider>
  );
}
