// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import paginationFactory, {PaginationProvider,  } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as uiHelpers from "../ReportsUIHelpers";
import { useReportsUIContext } from "../ReportsUIContext";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import * as reportsActions from '../../_redux/reportsActions'


export function OrderArticleReportTable() {

    const { listLoading, reportUpdated, totalCount, entities } = useSelector(
        (state) => ({
          listLoading: state.reports.listLoading,
          reportUpdated: state.reports.reportUpdated,
          totalCount: state.reports.report.totalCount,
          entities: state.reports.report.entities
        }),
        shallowEqual
    );

    const reportsUIContext = useReportsUIContext();
    const reportsUIProps = useMemo(() => {
      return {
        ids: reportsUIContext.ids,
        actualReportQueryParams: reportsUIContext.actualReportQueryParams,
        setActualReportQueryParams: reportsUIContext.setActualReportQueryParams,
      };
    }, [reportsUIContext]);

    
    const dispatch = useDispatch();

    useEffect(()=>{
      /* Para que funcione el paginado, recargo cada vez que hay un cambio en queryParams.
        Le pongo el if(entities) para que no dispare inicialmente  */

      if(entities && entities.length>0){
        dispatch(reportsActions.fetchReportsByName("order-articles", reportsUIProps.actualReportQueryParams));
      }
      
    },[ reportsUIProps.actualReportQueryParams])


    useEffect(()=>{
      dispatch(reportsActions.reset())
    }, [dispatch])

    const addressStyle = {
      inlineSize: "230px",
      overflowWrap: "break-word"
    };

    // Table columns
    const columns = [
        {
          dataField: "id",
          text: "ID",
          hidden:"true"
        },
        {
          dataField: "article",
          text: "Producto",
        },
        {
          dataField: "sku",
          text: "SKU"
        },
        {
          dataField: "qty",
          text: "Cantidad",
          //filter: textFilter()

        },

    ];
  

    const paginationOptions = {
      custom: true,
      totalSize: totalCount,
      sizePerPageList: uiHelpers.sizePerPageList,
      sizePerPage: reportsUIProps.actualReportQueryParams.pageSize,
      page: reportsUIProps.actualReportQueryParams.pageNumber,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Mostrando { from } a { to } de { size } Resultados
        </span>
      )
    };

    return (
        <>

          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => {
                return (

                  <Pagination
                    isLoading={listLoading}
                    paginationProps={paginationProps}
                  >

                    <BootstrapTable 
                      rowStyle={ { } }
                      wrapperClasses="table-responsive"
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                      remote 
                      keyField="id"
                      data={ entities === null ? [] : entities }
                      columns={ columns }
                      bordered={false}
                      condensed={true}
                      onTableChange={ getHandlerTableChange( reportsUIProps.setActualReportQueryParams) }
                      {...paginationTableProps}
                    >
                      <PleaseWaitMessage entities={entities} />
                      <NoRecordsFoundMessage entities={entities} />
                    </BootstrapTable>
                  </Pagination>

                );
              }}
          </PaginationProvider>
 
        </>
  );
}
