// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage, sortCaret } from "../../../../../../_metronic/_helpers";
import moment from 'moment';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect } from "react";
import * as alfabetaCrud from '../../../_redux/alfabetaCrud'
import { errorMsg, successMsg } from "../../../../Global/helpers";
import { PreciosDialog } from "../PreciosDialog";
import { useInventoryContext } from "../../../InventoryContext";
import * as storageHelpers from '../../../../../../_metronic/_helpers/LocalStorageHelpers'

const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
];

const numberFormat = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' })


export function AlfabetaList({viewProduct, viewHistoricalData, queryParams, setQueryParams, search, setSearch}) {
    // const [entities, setEntities] = useState([]);
    const [medicamento, setMedicamento] = useState({});
    const [showPrecios, setShowPrecios] = useState(false);
    const [hideFecha1, setHideFecha1] = useState(true);
    const [hideFecha2, setHideFecha2] = useState(true);
    const [hidePromedio, setHidePromedio] = useState(true);
    
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const inventoryContext = useInventoryContext();
    const{ selectedIds, setSelectedIds, setTotalIds, totalCountAB, setTotalCountAB, setFavoritesCount, loading, setLoading, entitiesAB, setEntitiesAB} = inventoryContext;
    const loadingRef = useRef(null);
    const firstLoadRef = useRef(true);

    const fetchProducts = async()=>{
        try{

          setLoading(true);
          const _products = await alfabetaCrud.getProductsAll(queryParams);

          if(_products && _products.data && _products.data.results){
              let ab = _products.data.results;
              let muestraFecha1 = ab.filter(i=>i.precio_fecha1.valor!=null)
              let muestraFecha2 = ab.filter(i=>i.precio_fecha2.valor!=null)              
              let promedio = ab.filter(i=>i.promedio_dias!=null)
              let _favorites = ab.filter(i=>i.notificar_cambios==1);

              let _totalIds = ab.map(r=>r.reg);

              if(muestraFecha1.length>0){
                setHideFecha1(false)
              }
              else{
                setHideFecha1(true)
              }
              if(muestraFecha2.length>0){
                setHideFecha2(false)
              }
              else{
                setHideFecha2(true)
              }
              if(promedio.length>0){
                setHidePromedio(false)
              }
              else{
                setHidePromedio(true)
              }
            
              setEntitiesAB(_products.data.results);
              setTotalCountAB(+_products.data.metadata.count);
              setFavoritesCount(+_favorites.length);
              setTotalIds(_totalIds);
              
              setCurrentPage(1)
              firstLoadRef.current = false;
              setSearch(false);
          }
          setLoading(false);
  
        }
        catch(err){
          setLoading(false)
          setSearch(false);
        }
    }


    const notificate = async(row)=>{
        try{
          if(!loadingRef.current ){
              if(row.notificar_cambios)
                await alfabetaCrud.notificationOFF({...row, id:row.reg})
              else
                await alfabetaCrud.notificationON({...row, id:row.reg})
              
              fetchProducts();
      
              successMsg("Registro actualizado correctamente")
          }
        }
        catch(err){
            errorMsg("Error al actualizar registro")
        }
    }
    

    useEffect(() => {
        loadingRef.current = loading;
    }, [loading]);

    // useEffect(()=>{
    //     fetchProducts();
    // }, [queryParams])
    useEffect(()=>{
        let _memVars = JSON.parse(storageHelpers.getStorage("FilterAlfabeta"));
        if(_memVars){
            const filter_exists = Object.values(_memVars).filter(d=>d!="" && d!=null && d!=undefined);
            const query_exists = Object.values(queryParams.filter).filter(d=>d!="" && d!=null && d!=undefined);
            const firstRender = firstLoadRef.current;
    
            if(filter_exists.length>0 && query_exists.length>0 && firstRender){
                firstLoadRef.current = false;            
                fetchProducts();
            }
        }
    }, [queryParams])

    useEffect(()=>{
      if(search){
        fetchProducts();
      }
    }, [search])

    useEffect(()=>{
        setTotalCountAB(0);
        setEntitiesAB([]);
    }, [])

    /* Esto se lo agrego para que funcione bien el paginado, ya que no estoy escuchando el queryParams event */
    // useEffect(()=>{
    //     if(!initialLoading)
    //       fetchProducts();
    // }, [queryParams.pageNumber, queryParams.pageSize])

    const columns = [
        {
            dataField: "reg",
            text:"REG",
            sort: true,
            sortCaret: sortCaret,
            // formatter: (  cellContent, row, rowIndex) => (
            //     <a onClick={() => openEditProfesionPage(row)} className="text-primary"> {cellContent}</a>
            // )
        },
        {
            dataField: "nom",
            text:"Nombre",
            sort: true,
            sortCaret: sortCaret,
            formatter:(cell, row) => (
              <span>
                <p className="mb-0"><span className="font-weight-bold">{ cell} </span> <span>{ row.pres }</span></p>
                <p className="mb-0">{ row.monodroga }</p>
                <p className="mb-0">{ row["lab"].name }</p>
              </span>
            )
        },  
        {
            dataField: "precio",
            text:"Actual",
            sort: true,
            sortCaret: sortCaret,
            headerClasses: 'text-right',
            style: { textAlign: 'right' },
            formatter:(cell, row)=>{
              return (
                <span>
                  <p className="mb-0 font-weight-bold">{(row.precio_actual && row.precio_actual.valor) ? numberFormat.format(row.precio_actual.valor) : ''}</p>
                  <p style={{ marginBottom:'0' }}>{ (row.precio_actual && row.precio_actual.f_inicio)? moment(row.precio_actual.f_inicio).format("DD MMM") + ' ' + moment(row.precio_actual.f_inicio).format("YYYY") :"" }</p>
                </span>
              )
            }
        },    
        {
            dataField: "precio_actual.dias",
            text:"Vigencia (días)",
            sort: true,
            sortCaret: sortCaret,
            headerClasses: 'text-right',
            style: { textAlign: 'right' }
        },       
        {
            dataField: "promedio_dias",
            text:"Prom. Cambio (días)",
            sort: true,
            sortCaret: sortCaret,
            headerClasses: 'text-right',
            hidden:hidePromedio,
            style: { textAlign: 'right' }
        },     
        {
            dataField: "precio_anterior.variacion",
            text:"Ult. Variación",
            sort: true,
            sortCaret: sortCaret,
            headerClasses: 'text-right',
            style: { textAlign: 'right' },
            formatter:(cell, row) => (<span>{cell?cell.toFixed(2) + '%' : ''}</span>)
        },   
        {
            dataField: "precio_fecha2.valor",
            text:"Desde",
            sort: true,
            sortCaret: sortCaret,
            headerClasses: 'text-right',
            style: { textAlign: 'right' },
            hidden:hideFecha2,
            formatter:(cell, row) => (
              <span>
                <p className="mb-0 font-weight-bold">{(row.precio_fecha2 && row.precio_fecha2.valor) ? numberFormat.format(row.precio_fecha2.valor) : ''}</p>
                <p style={{ marginBottom:'0' }}>{ (row.precio_fecha2 && row.precio_fecha2.f_inicio)? moment(row.precio_fecha2.f_inicio).format("DD MMM") + ' ' + moment(row.precio_fecha2.f_inicio).format("YYYY") :"" }</p>
              </span>
            )
        },   
        {
            dataField: "precio_fecha1",
            text:"Hasta",
            sort: true,
            sortCaret: sortCaret,
            headerClasses: 'text-right',
            style: { textAlign: 'right' },
            hidden:hideFecha1,
            formatter:(cell, row) => (
              <span>
                <p className="mb-0 font-weight-bold">{(cell && cell.valor) ? numberFormat.format(cell.valor) : ''}</p>
                <p style={{ marginBottom:'0' }}>{ (cell && cell.f_inicio)? moment(cell.f_inicio).format("DD MMM") + ' ' + moment(cell.f_inicio).format("YYYY") :"" }</p>
              </span>

            )
        },    
        // {
        //     dataField: "precio_fecha1.valor",
        //     text:"Precio 1",
        //     headerClasses: 'text-right',
        //     style: { textAlign: 'right' },
        //     formatter:(cell)=>{
        //       return (<span>{cell ? numberFormat.format(cell) : ''}</span>)
        //     }
        // },   
        // {
        //     dataField: "precio_fecha2.valor",
        //     text:"Precio 2",
        //     headerClasses: 'text-right',
        //     style: { textAlign: 'right' },
        //     formatter:(cell)=>{
        //       return (<span>{cell ? numberFormat.format(cell) : ''}</span>)
        //     }
        // }, 
        //Porcentaje de aumento = ((Precio nuevo - Precio antiguo) / Precio antiguo) * 100
        {
            dataField: "precio_fecha2.variacion",
            text:"Var %",
            sort: true,
            sortCaret: sortCaret,
            headerClasses: 'text-right',
            style: { textAlign: 'right' },
            hidden:hideFecha2,
            formatter:(cell)=>{
              return (<span>{cell ? cell.toFixed(2) + '%': ''}</span>)
            }
        }, 


        {
          dataField: "acciones",
          text: "",
          dummyField: true,
          classes:"text-right",
          headerClasses: "text-center",
          headerStyle: () => {
            return { minWidth: '120px' };
            },
          formatter: (cell, row)=>{
              return (
                <>
                  <OverlayTrigger overlay={<Tooltip id="mov-detail-tooltip">Ver detalle {row.nom}</Tooltip>} >
                      <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1" onClick={() => {viewProduct(row)}} > <span><i className="far fa-eye text-primary"></i></span> </a>
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip id="waves-print-tooltip">Ver historial de precios</Tooltip>} >
                      <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1" onClick={() => {viewHistoricalData(row.reg)}} > <span><i className="fas fa-history text-info"></i></span> </a>
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip id="waves-print-tooltip">{row.notificar_cambios?'Quitar de ':'Agregar a '} favoritos </Tooltip>} >
                      <a className={`btn btn-icon btn-light btn-sm mx-1${row.notificar_cambios?"btn-hover-warning":"btn-hover-default"}`} onClick={() => { notificate(row)}} > 
                        <span><i className={`fas fa-star ${row.notificar_cambios?"text-warning":"text-default"}`} ></i></span> </a>
                  </OverlayTrigger>
                </>
            )
          }
        },

    ];
  
    function handlePageChange(page, sizePerPage){
      setCurrentPage(page)
      setPageSize(sizePerPage)        
    }
    function handleSizePerPageChange(sizePerPage){
      setCurrentPage(1)
      setPageSize(sizePerPage)        
    }
    const paginationOptions = {
      custom: true,
      totalSize: totalCountAB,
      sizePerPageList: sizePerPageList,
      sizePerPage: pageSize,
      page:currentPage,
      onPageChange:handlePageChange,
      onSizePerPageChange:handleSizePerPageChange,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Mostrando { from } a { to } de { size } Resultados
        </span>
      )
    };    

    // const paginationOptions = {
    //     custom: true,
    //     totalSize: totalCount,
    //     sizePerPageList: sizePerPageList,
    //     sizePerPage: queryParams.pageSize,
    //     page: queryParams.pageNumber,
    //     paginationTotalRenderer: (from, to, size) => (
    //       <span className="react-bootstrap-table-pagination-total">
    //         Mostrando { from } a { to } de { size } Resultados
    //       </span>
    //     )
    //   };    

      const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selectColumnPosition: 'left',
        hideSelectAll: true,
        //selectColumnStyle: { cursor: 'pointer' },
        bgColor: '#C9F7F5',
        selected:selectedIds,
        onSelect: (row, isSelect, rowIndex, e) => {
          if (true) {
            if(isSelect){
              setSelectedIds([...selectedIds, row.reg]);
                
            }
            else{
              const newArr = selectedIds.filter(item=>item!=row.reg);
              setSelectedIds(newArr)
            }
          }
        },
    
      };


  return (
    <>
        <PreciosDialog show={showPrecios} onHide={()=>setShowPrecios(false)} medicamento={medicamento}/>

        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
                return (
                <>
                  <Pagination paginationProps={paginationProps} >

                      <BootstrapTable
                          wrapperClasses="table-responsive"
                          classes="table table-head-custom table-vertical-center overflow-hidden"
                          bootstrap4
                          condensed
                          // remote 
                          keyField="reg"
                          data={ entitiesAB === null ? [] : entitiesAB }
                          columns={ columns }
                          bordered={false}  
                          selectRow={ selectRow } 
                          // onTableChange={ getHandlerTableChange( setQueryParams) }
                          {...paginationTableProps}
                          >
                          <PleaseWaitMessage entitiesAB={entitiesAB} />                      
                          <NoRecordsFoundMessage entitiesAB={entitiesAB} />
                      </BootstrapTable>
                  </Pagination>
                </>
            );
            }}
        </PaginationProvider>


    </>
  );
}

















