import * as requestFromServer from "./printCrud";
import {printSlice, callTypes} from "./printSlice";

const {actions} = printSlice;


export const printGuide = obTransfer => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .printGuide(obTransfer)
      .then(response => {
        const  print  = response.data;
        const file = new Blob([print], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");   
        dispatch(actions.printGuideCreated());
      })
      .catch(error => {
        error.clientMessage = "Can't create order";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };

export const printGuideFromPharmacy = order_id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .printGuideFromPharmacy(order_id)
    .then(response => {
      const  print  = response.data;
      const file = new Blob([print], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");   
      dispatch(actions.printGuideCreated());
    })
    .catch(error => {
      error.clientMessage = "Can't create order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const printTickets = obTransfer => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .printTickets(obTransfer)
      .then(response => {
        const  print  = response.data;
        dispatch(actions.printTicketCreated());
      })
      .catch(error => {
        error.clientMessage = "Error al imprimir tickets";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };  


  export const printWaybill = waybill_id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .printWaybill(waybill_id)
      .then(response => {
        const  print  = response.data;
        const file = new Blob([print], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");   

        dispatch(actions.printWaybillCreated({ print }));
      })
      .catch(error => {
        error.clientMessage = "Can't create order";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };  


  export const printConsolidated = wave_id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .printConsolidated(wave_id)
      .then(response => {
        const  print  = response.data;
        const file = new Blob([print], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");   

        dispatch(actions.printConsolidatedCreated({ print }));
      })
      .catch(error => {
        error.clientMessage = "Can't print consolidated";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };

  export const printDepotMovement = wave_id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .printDepotMovement(wave_id)
      .then(response => {
        const  print  = response.data;
        const file = new Blob([print], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");   

        dispatch(actions.printDepotMovementCreated({ print }));
      })
      .catch(error => {
        error.clientMessage = "Can't print DepotMovement";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };


