import React from "react";
import { Switch } from "react-router-dom";
import { ContentRoute } from "../../../../../_metronic/layout";
import { PharmaAccountCard } from "./PharmaAccountCard";

                  
export function PharmaAccountPage({ history }) {


  return (
      <Switch>
          <ContentRoute component={PharmaAccountCard} />
      </Switch>
  )
}