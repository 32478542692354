import * as pharmaDeliveriesCrud from "./pharmaDeliveriesCrud";
import {pharmaDeliveriesSlice, callTypes} from "./pharmaDeliveriesSlice";

const {actions} = pharmaDeliveriesSlice;

export const fetchOrders = queryParams => dispatch => {

  dispatch(actions.startCall({ callType: callTypes.list }));
  return pharmaDeliveriesCrud
    .getOrders(queryParams)
    .then(response => {
      const totalCount=response.data.metadata.count;
      const entities=response.data.results;
      dispatch(actions.ordersFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const updateOrder = order => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return pharmaDeliveriesCrud
    .updateOrder(order)
    .then(() => {
      dispatch(actions.orderUpdated({ order }));
    })
    .catch(error => {
      error.clientMessage = "Can't update order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const setPayment = (order,paid) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return pharmaDeliveriesCrud
    .setPayment(order.id, paid)
    .then(() => {
      dispatch(actions.orderPaid());
      
    })
    .catch(error => {
      error.clientMessage = "Can't update order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};



export const confirmDeliveries = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  
  return pharmaDeliveriesCrud
    .confirmDeliveries(params)
    .then(response => {
      dispatch(actions.deliveryConfirmed());
    })
    .catch(error => {
      error.clientMessage = "Error al confirmar las entregas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
