export const OrderStatusCssClasses = ["success", "info", ""];
export const OrderStatusTitles = ["Selling", "Sold"];
export const OrderConditionCssClasses = ["success", "danger", ""];
export const OrderConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "desc" }];

export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 }
];

export const LOGISTIC_STATE ={
  PENDING : {value:1, label:'Pendiente', color:'danger'},
  IN_PROCESS : {value:2, label:'En Armado', color:'warning'},
  DISPATCHED : {value:3, label:'En Distribución', color:'warning'},
  DELIVERED : {value:4, label:'Entregado', color:'success'},
  CANCELED : {value:5, label:'Cancelado', color:'secondary'},
  NO_DISPATCH : {value:6, label:'Sin Despacho', color:'danger'},
}
export const LOGISTIC_STATE_ARRAY = {
  1:{label:'Pendiente', color:'danger'},
  2:{label:'En Armado', color:'warning'},
  3:{label:'En Distribución', color:'warning'},
  4:{label:'Entregado', color:'success'},
  5:{label:'Cancelado', color:'secondary'},
  6:{label:'Sin Despacho', color:'danger'}
}

export const initialFilter = {
  filter: {
    logistic_state_id:LOGISTIC_STATE.PENDING.value.toString() //+ ', ' + LOGISTIC_STATE.NO_DISPATCH.value.toString()//por defecto trae las pendientes
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 20
};



export const optionsSelectDeliveryConfirmation = [
  {"value":1, "label": "Entregado"}, 
  {"value":2, "label": "Devuelto"}, 
  //{"value":3, "label": "Pendiente de Devolución"}
];

export const stylesSelectDelivery = { 
  menuPortal: (base) => ({ ...base, zIndex: 9999}),
  control: (css) => ({
      ...css,
      width: 220, marginLeft:"2px"
      }),
      menu: ({ width, ...css }) => ({
      ...css,
      width: "max-content"       
      }),
      // Add padding to account for width of Indicators Container plus padding
      option: (css) => ({ ...css, width: 220 }),

}

export const stylesSelectReturnedCause = { 
  menuPortal: (base) => ({ ...base, zIndex: 9999}),
  control: (css) => ({
      ...css,
      width: 220, marginLeft:"2px"
    }),
    menu: ({ width, ...css }) => ({
      ...css,
      width: "max-content"        
    }),
    // Add padding to account for width of Indicators Container plus padding
    option: (css) => ({ ...css, width: 220 }),
    container: (css) => ((false)?{ ...css, border:'solid 1px red'  }:{ ...css}),
}


/*
FILTERS
    id:null,
    state_id: null,
    shipping_type: null,
    shipping_carrier_service_id: null,
    zone_id: null,
    wave_id: null,
    waybill_id: null,
    client_id:null,
    client_name:null,
    inWave:null,
    inWaybill:null,
    shipped:null,
    dispatched:null,
*/