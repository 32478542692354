
import React, { useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "lodash";
import * as storageHelpers from '../../../../../../_metronic/_helpers/LocalStorageHelpers'
import ReactDatePicker from "react-datepicker";
import { Form } from "react-bootstrap";

const initialFilter={
    filter: {    
        text:""
    },
    sortOrder: "asc", 
    sortField: "name",
    pageNumber: 1,
    pageSize: 10
}
const prepareFilter = (queryParams, values) => {
    const { searchText } = values;
    
    const newQueryParams = { ...queryParams, pageNumber:1, pageSize:10, filter:{...queryParams?.filter || {}} };


    if (searchText && searchText!='') {
      newQueryParams.filter.text = searchText;
    }
    else{
      newQueryParams.filter.text = undefined;
    }

    return newQueryParams;
};


export function ProductsFilters({ queryParams, setQueryParams }) {
    const [memVars, setMemVars] = useState(null);
    const [priceAt, setPriceAt] = useState(null);
    const [formDefaultValues, setFormDefaultValues] = useState({});

    useEffect(()=>{

        let _memVars = JSON.parse(storageHelpers.getStorage("FilterProducts"));

        if(_memVars  ){
          setMemVars(_memVars);
          let _formValues = {
            text : _memVars?.filter?.text
         }

          setFormDefaultValues(_formValues);

          setQueryParams(_memVars)
        }
        else{
          setQueryParams(initialFilter)
        }

    }, [])


    useEffect(()=>{

      if(Object.keys(formDefaultValues).length != 0){
        let values = {
          "searchText":formDefaultValues.searchText
        }

        const newQueryParams = prepareFilter(queryParams, values);

        if (!isEqual(newQueryParams, queryParams)) {
            setQueryParams(newQueryParams);
            storageHelpers.setStorage(
                'FilterProducts',
                JSON.stringify(newQueryParams))
        }
      }

    }, [formDefaultValues])


  return (
    <>
{/* innerRef={formRef} */}
          {/* <form  className="form form-label-right" > */}
            <div className="form-group row">
              
              <div className="col-lg-6">
                <input
                  type="text"
                  className="form-control"
                  name="text"
                  placeholder="Search"
                  value={formDefaultValues?.searchText || ""}
                  onChange={e=>setFormDefaultValues({...formDefaultValues, searchText:e.target.value})}
                />
                <small className="form-text text-muted">
                  Buscar por <b>Nombre</b>
                </small>
              </div>

            </div>

          {/* </form> */}
    </>
  );
}



