import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  OrderStatusCssClasses,
  OrderStatusTitles
} from "../../PharmaDeliveriesUIHelpers";

export const NotesColumnFormatter = (cellContent, row) => {
    
    const internas = row.notes?row.notes.map(item=>item.note).join(' (interna)\n'):'';
    const notes = cellContent ? cellContent + '\n' + internas : internas;

    return (

        <span style={{  }}>
            { notes?
                <OverlayTrigger overlay={<Tooltip id="customers-edit-tooltip">{notes}</Tooltip>} >
                    <i className={"fas fa-comment text-primary"}></i>
                </OverlayTrigger>
                :
                <span></span> 
            }
        </span>

    )};



