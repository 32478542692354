export const OrderStatusCssClasses = ["success", "info", ""];
export const OrderStatusTitles = ["Selling", "Sold"];

export const defaultSorted = [{ dataField: "id", order: "desc" }];

export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 }
];
export const initialFilter = {
  filter: {
    shipping_type:"3",
    delivered:"0",
    logistic_state_id:"1,6,2,3,4"
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};

