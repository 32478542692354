import axios from "axios";

export const ALFABETA_URL =`${process.env.REACT_APP_API_URL}/ab/article`;
export const ALFABETA_ALL_URL =`${process.env.REACT_APP_API_URL}/ab/article-all`;
export const ALFABETA_MIN_URL =`${process.env.REACT_APP_API_URL}/ab/article-min`;
export const ALFABETA_STATUS_URL =`${process.env.REACT_APP_API_URL}/ab/status`;

let cancelToken;

export function getProducts(params) {
    return (async () => {
        try {
            if (typeof cancelToken != typeof undefined) {
              cancelToken.cancel("Cancelado por new Request.")
            }
            cancelToken = axios.CancelToken.source();

            const resp = await axios.get(ALFABETA_URL, { params, cancelToken: cancelToken.token });

            return resp;

        } catch (err) {
          
            if (axios.isCancel(err)) {
              //console.log('Request canceled');
            } else {
              // handle error
            }
            throw err;
        }
    })();
}

export function getProductsAll(params) {
  return (async () => {
      try {
          if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Cancelado por new Request.")
          }
          cancelToken = axios.CancelToken.source();
//           console.log(params)
//           const {pageNumber, pageSize, ... lastingParams} = params;
//  console.log(lastingParams)
          const resp = await axios.get(ALFABETA_ALL_URL, { params, cancelToken: cancelToken.token });

          return resp;

      } catch (err) {
        
          if (axios.isCancel(err)) {
            //console.log('Request canceled');
          } else {
            // handle error
          }
          throw err;
      }
  })();
}

export function getLastProcessDate(id) {
  return axios.get(`${ALFABETA_STATUS_URL}`);
}

export function getProductsMin(params) {
  return (async () => {
      try {
          if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Cancelado por new Request.")
          }
          cancelToken = axios.CancelToken.source();

          const resp = await axios.get(ALFABETA_MIN_URL, { params, cancelToken: cancelToken.token });

          return resp;

      } catch (err) {
        
          if (axios.isCancel(err)) {
            //console.log('Request canceled');
          } else {
            // handle error
          }
          throw err;
      }
  })();
}

export function getProductById(params) {
  return (async () => {
      try {
          if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Cancelado por new Request.")
          }
          cancelToken = axios.CancelToken.source();

          const resp = await axios.get(`${ALFABETA_URL}/${params.id}`, { params, cancelToken: cancelToken.token });

          return resp;

      } catch (err) {
        
          if (axios.isCancel(err)) {
            //console.log('Request canceled');
          } else {
            // handle error
          }
          throw err;
      }
  })();
}

export function notificationON(product){
  return axios.post(`${ALFABETA_URL}/${product.id}/notify/on`,  product );
}

export function notificationOFF(product){
  return axios.post(`${ALFABETA_URL}/${product.id}/notify/off`,  product );
}

export function notificationONMany(ob){
  return axios.post(`${ALFABETA_URL}/notify/on`,  ob );
}

export function notificationOFFMany(ob){
  return axios.post(`${ALFABETA_URL}/notify/off`,  ob );
}

export function updateAlfabeta(product){
  return axios.put(`${ALFABETA_URL}/${product.id}`,  product );
}
