import axios from "axios";

export const ORDERS_URL =`${process.env.REACT_APP_API_URL}/orders`;// "api/orders";
export const WAVES_URL =`${process.env.REACT_APP_API_URL}/waves`;


// READ
export function getOrders(params) {
  return axios.get(ORDERS_URL,{ params });
}

export function getOrderById(orderId) {
  return axios.get(`${ORDERS_URL}/${orderId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOrders(queryParams) {
  return axios.post(`${ORDERS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateOrder(order) {
  return axios.put(`${ORDERS_URL}/${order.id}`, { order });
}


export function confirmDeliveries(orders) {
  return axios.post(ORDERS_URL + '/delivery-status', orders);
}


export function setPayment(order_id, paid) {
  if(paid){
    return axios.post(`${ORDERS_URL}/` + order_id + '/paid' );
  }
  else{
    return axios.delete(`${ORDERS_URL}/` + order_id + '/paid' );
  }
  
}
