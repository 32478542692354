import React, {useMemo, useEffect, useState} from "react";
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import { usePharmaDeliveriesUIContext } from "../PharmaDeliveriesUIContext";

import { isEqual } from "lodash";

const prepareFilter = (queryParams, val) => {
  const newQueryParams = { ...queryParams, filter:{...queryParams.filter} };
  if(val=="Pendientes"){
    newQueryParams.filter.delivered = 0;
  }
  else{
    newQueryParams.filter.delivered = undefined;
  }
  return newQueryParams;
};

export function PharmaDeliveriesViews() {

    const pharmaDeliveriesUIContext = usePharmaDeliveriesUIContext();
    const pharmaDeliveriesUIProps = useMemo(() => {
      return {
        queryParams: pharmaDeliveriesUIContext.ordersQueryParams,
        setQueryParams: pharmaDeliveriesUIContext.setOrdersQueryParams,
        defaultView: pharmaDeliveriesUIContext.defaultView,
        setDefaultView: pharmaDeliveriesUIContext.setDefaultView,
      };
    }, [pharmaDeliveriesUIContext]);

    const handleChange = (val) => {

      pharmaDeliveriesUIProps.setDefaultView(val);

      const newQueryParams = prepareFilter(pharmaDeliveriesUIProps.queryParams, val);

      if (!isEqual(newQueryParams, pharmaDeliveriesUIProps.queryParams)) {
        newQueryParams.pageNumber = 1;
        pharmaDeliveriesUIProps.setQueryParams(newQueryParams);
      }
    }

    useEffect(()=>{
      const newQueryParams = prepareFilter(pharmaDeliveriesUIProps.queryParams, pharmaDeliveriesUIProps.defaultView);
      
      newQueryParams.pageNumber = 1;
      pharmaDeliveriesUIProps.setQueryParams(newQueryParams);

    }, [])

    return (

        <ToggleButtonGroup type="radio" name="options" value={pharmaDeliveriesUIProps.defaultView} onChange={handleChange}>
            <ToggleButton id="tbg-radio-1" value={"Pendientes"}  size="sm" variant="default" className="mr-1">
              Pendientes
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value={"Activas"}  size="sm" variant="default">
            Activas
            </ToggleButton>
        </ToggleButtonGroup>

      );
}























