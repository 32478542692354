// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import paginationFactory, {PaginationProvider,  } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as uiHelpers from "../ReportsUIHelpers";
import { useReportsUIContext } from "../ReportsUIContext";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import * as reportsActions from '../../_redux/reportsActions'


export function OrderReportTable() {

    const { listLoading, reportUpdated, totalCount, entities } = useSelector(
        (state) => ({
          listLoading: state.reports.listLoading,
          reportUpdated: state.reports.reportUpdated,
          totalCount: state.reports.report.totalCount,
          entities: state.reports.report.entities
        }),
        shallowEqual
    );

    const reportsUIContext = useReportsUIContext();
    const reportsUIProps = useMemo(() => {
      return {
        ids: reportsUIContext.ids,
        actualReportQueryParams: reportsUIContext.actualReportQueryParams,
        setActualReportQueryParams: reportsUIContext.setActualReportQueryParams,
        withDetails:reportsUIContext.withDetails
      };
    }, [reportsUIContext]);

    
    const dispatch = useDispatch();

    useEffect(()=>{
      /* Para que funcione el paginado, recargo cada vez que hay un cambio en queryParams.
        Le pongo el if(entities) para que no dispare inicialmente  */

      if(entities && entities.length>0){
        dispatch(reportsActions.fetchReportsByName("orders", reportsUIProps.actualReportQueryParams));
      }
      
    },[ reportsUIProps.actualReportQueryParams])


    useEffect(()=>{
      dispatch(reportsActions.reset())
    }, [dispatch])

    const addressStyle = {
      inlineSize: "230px",
      overflowWrap: "break-word"
    };

    // Table columns
    const columns = [
        {
          dataField: "id",
          text: "ID",
        },
        {
          dataField: "source_name",
          text: "Origen",
          formatter: (cellContent, row) => (
            <span>
              <p style={{ marginBottom:'0' }}>{ cellContent=='WOOCOMMERCE'? 'SHOP':cellContent}</p>              
            </span>
            )
        },
        {
          dataField: "order_creation",
          text: "Fecha",
          //filter: textFilter(),
          formatter: (cellContent, row) => (
            <span>
              {/* <p style={{ marginBottom:'0' }}>ID: <b>{row.waybill_id}</b></p> */}
              <p style={{ marginBottom:'0' }}>{ moment(cellContent).format("DD/MM/YYYY") }</p>
              <p style={{ marginBottom:'0' }} className="text-muted">{ moment(cellContent).format("HH:mm") }</p>
            </span>
            )
        },
        {
          dataField: "customer_first_name",
          text: "Cliente",
          formatter: (cellContent, row) => (
            <span>
                <p style={{ marginBottom:'0' }}>{ row.customer_first_name }</p>
                <p style={{ marginBottom:'0' }}>{ row.customer_last_name }</p>
                <p style={{ marginBottom:'0' }}>ID Cliente Shop: { row.customer_origin_id }</p>
                <p style={{ marginBottom:'0' }}>DBT: { row.customer_dbt_type_desc }</p>
            </span>
            )
        },
        {
          dataField: "shipping_type_origin_name",
          text: "Envío"
        },
        {
          dataField: "address_street",
          text: "Dirección",
          hidden:reportsUIProps.withDetails,
          //filter: textFilter(),
          formatter: (cellContent, row) => (
            <span>
              <p className="mb-0" style={addressStyle}>{ row.address_street+ ' ' + row.address_street_number  + '  ' + row.address_floor + ' ' + row.address_apartment}</p>
              <p className="mb-0">{ row.address_locality_name + ' ' +  (row.address_postal_code?'('+row.address_postal_code+')':'')}</p>
              <p className="mb-0 font-weight-bold">{ row.address_state_name }</p>
            </span>
            )
        },
        {
        dataField: "product_name",
        text: "Producto",
        hidden:!reportsUIProps.withDetails,
        //filter: textFilter(),

        },
        {
        dataField: "order_line_qty",
        text: "Cantidad",
        classes:"text-center",
        hidden:!reportsUIProps.withDetails,
        //filter: textFilter(),

        },
        {
          dataField: "total",
          text: "$ Total"
        },
        {
          dataField: "payment_type_name",
          text: "Pago",
          classes:"text-center",
          headerClasses:"text-center",
          formatter: (cellContent, row) => (
            <span>{ cellContent?cellContent.replace("Pago", ""):''} </span>
            )
        },
        {
          dataField: "delivery_count",
          text: "Despachos",
          classes:"text-center"
        },
        {
          dataField: "delivered_date",
          text: "Entrega",
          formatter: (cellContent, row) => (
            <span>
              <p style={{ marginBottom:'0' }}>{ cellContent?moment(cellContent).format("DD/MM/YYYY"):"" }</p>
              <p style={{ marginBottom:'0' }} className="text-muted">{ cellContent?moment(cellContent).format("HH:mm"):"" }</p>
            </span>
            )
        },
        {
          dataField: "invoice",
          text: "Comprobante",
        },

    ];
  

    const paginationOptions = {
      custom: true,
      totalSize: totalCount,
      sizePerPageList: uiHelpers.sizePerPageList,
      sizePerPage: reportsUIProps.actualReportQueryParams.pageSize,
      page: reportsUIProps.actualReportQueryParams.pageNumber,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Mostrando { from } a { to } de { size } Resultados
        </span>
      )
    };

    return (
        <>

          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => {
                return (

                  <Pagination
                    isLoading={listLoading}
                    paginationProps={paginationProps}
                  >

                    <BootstrapTable 
                      rowStyle={ { fontSize: "smaller" } }
                      wrapperClasses="table-responsive"
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                      remote 
                      keyField="idx"
                      data={ entities === null ? [] : entities }
                      columns={ columns }
                      bordered={false}
                      condensed={true}
                      onTableChange={ getHandlerTableChange( reportsUIProps.setActualReportQueryParams) }
                      {...paginationTableProps}
                    >
                      <PleaseWaitMessage entities={entities} />
                      <NoRecordsFoundMessage entities={entities} />
                    </BootstrapTable>
                  </Pagination>

                );
              }}
          </PaginationProvider>
 
        </>
  );
}
