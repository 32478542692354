import React, { createContext, useContext, useState, useEffect } from "react";

const InventoryContext = createContext();

export function useInventoryContext() {
  return useContext(InventoryContext);
}

export const InventoryConsumer = InventoryContext.Consumer;


export function InventoryProvider({ children }) {

    const [selectedIds, setSelectedIds] = useState([]);
    const [totalIds, setTotalIds] = useState([]);
    const [totalCountAB, setTotalCountAB] = useState(0);
    const [favoritesCount, setFavoritesCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [entitiesAB, setEntitiesAB] = useState([]);


    const value = {
        entitiesAB,
        setEntitiesAB,
        selectedIds,
        setSelectedIds,
        totalIds,
        setTotalIds,
        totalCountAB,
        setTotalCountAB,
        favoritesCount,
        setFavoritesCount,
        loading, 
        setLoading
    };

    return (
        <InventoryContext.Provider value={value}>
        {children}
        </InventoryContext.Provider>
    );
}

