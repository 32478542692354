import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { shallowEqual, useSelector } from "react-redux";
import Select from 'react-select';

export function DashboardFilters({className, filter, setFilter}) {

    const { business_units } = useSelector(
        (state) => ({ business_units: state.helpers.BusinessUnits }),
        shallowEqual
      );

    return (
        <>
        <div className={`card card-custom ${className}`}>
            <div className="form-group row justify-content-end  mb-0">
                <div className="col-md-2 col-sm-12 p-10">
                    <ReactDatePicker
                        autoComplete='off'
                        className="form-control"
                        style={{ width: "100%" }}
                        name="dateFrom"
                        selected={filter.dateFrom}
                        maxDate={filter.dateTo?(filter.dateTo>new Date()?new Date():filter.dateTo):new Date()}
                        dateFormat="dd/MM/yyyy"
                        onChange={date=>{ setFilter({...filter, dateFrom:date}) }}

                    />
                    <small className="form-text text-muted">
                    Fecha <b>desde</b>
                    </small>
                </div>
                <div className="col-md-2 col-sm-12 p-10">
                    <ReactDatePicker
                        autoComplete='off'
                        className="form-control"
                        style={{ width: "100%" }}
                        name="dateTo"
                        selected={filter.dateTo}
                        minDate={filter.dateFrom}
                        dateFormat="dd/MM/yyyy"
                        onChange={date=>{ setFilter({...filter, dateTo:date}) }}

                    />
                    <small className="form-text text-muted">
                    Fecha <b>hasta</b>
                    </small>
                </div>
                <div className="col-md-4 col-sm-12 p-10">
                    <Select 
                        options={business_units} 
                        value = {filter.businessUnit}
                        onChange={value=>{setFilter({...filter, businessUnit:value})}}
                    />
                    <small className="form-text text-muted">
                    Filtro por <b>Unidad de Negocio</b>
                    </small>
                </div> 
            </div>            
        </div>
        </>
    )
}