import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import * as ordersActions from "../../../_redux/orders/ordersActions"
import { useDispatch } from "react-redux";
import { errorMsg, successMsg } from "../../../../Global/helpers";


export function CancelOrderDialog({ show, onHide, order }) {
    const [reason, setReason] = useState("");
    const [validated, setValidated] = useState(false);



    const dispatch = useDispatch();
    const cancelOrder=()=>{
        if(!reason || reason.trim()==''){
            setValidated(true)
            return;
        }
        try{
            dispatch(ordersActions.cancelOrder(order.id, reason));
            successMsg(`Orden #${order.id} cancelada`);
        }
        catch(err){
            errorMsg("Error al cancelar la orden")
        }
        onHide();
    }

    return (
        <>
        <Modal size="md" show={show}  backdrop='static' >
            <Modal.Header closeButton>
              <Modal.Title>Cancelar una Órden</Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay overlay-block cursor-default">
                <Form validated={validated}>
                    <p>Confirma la cancelación de la órden #<b>{order?order.id:""}</b></p>
                    <p>Perteneciente al cliente <b>{order?(order.customer?.first_name + ' ' + order.customer?.last_name):""}</b>  ? </p>
                    <Form.Group>
                    <Form.Label>Motivo</Form.Label>
                    <Form.Control type="text" value={reason} onChange={e=>setReason(e.target.value)} className="form-control" required/>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer style={{borderTop: "0 none"}}>
              <Button variant="secondary" onClick={onHide}>No</Button>
              <Button variant="primary" onClick={()=>cancelOrder()}>Sí, cancelar la Orden</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
