import axios from "axios";

export const ORDERS_URL =`${process.env.REACT_APP_API_URL}/orders`;

export function getPharmaDailyAccount(params) {
    //filter={"order_delivered_from":"2022-03-03", "order_delivered_to":"2022-03-03","shipping_type":"3"}&sortOrder=desc&sortField=id&pageNumber=1&pageSize=10
    return axios.get(ORDERS_URL,{ params });
  }





