import React, {useMemo, useEffect, useState, useRef} from "react";
import { CSVLink } from "react-csv";
import * as reportsCrud from "../../_redux/reportsCrud";
import { useReportsUIContext } from "../ReportsUIContext";
import moment from "moment";


export function WaybillReportToCSV() {

    const [dataCSV, setDataCSV] = useState([]);
    const [loading, setLoading] = useState(false);
    const csvRef = useRef();
    
    const reportsUIContext = useReportsUIContext();
    const reportsUIProps = useMemo(() => {
      return {
        queryParams: reportsUIContext.actualReportQueryParams,
      };
    }, [reportsUIContext]);

    const getWaybills = ()=>{
        const newQueryParams = {...reportsUIProps.queryParams}
        newQueryParams.pageSize = null;
        if(!loading) {
            setLoading(true);
            reportsCrud.getReportByName("waybills", newQueryParams).then((response) => {
                let datos = response.data.results.map(item => { 
                        let linea={
                            orderId:item.order_id,
                            fechaPedido:moment(item.order_creation).format("DD/MM/YYYY HH:mm") ,
                            fechaDespacho:moment(item.dispatched_date).format("DD/MM/YYYY HH:mm"),
                            fechaEntrega:item.delivered_date ? moment(item.delivered_date).format("DD/MM/YYYY HH:mm") : '',
                            fechaDevolucion:item.returned_date ? moment(item.returned_date).format("DD/MM/YYYY HH:mm") : '',
                            motivoDevolucion:item.returned_reason_name,
                            idDespacho:item.waybill_id,
                            nombre:item.customer_first_name,
                            apellido:item.customer_last_name,
                            dni:item.billing_tax_number,
                            id_cliente_shop:item.customer_origin_id,
                            dbt:item.customer_dbt_type_desc,
                            transporte:item.sc_name,
                            servicio:item.scs_name,
                            puntoPickit:item.pickit_point,
                            localidad:item.locality_name,
                            provincia:item.state_name,
                            totalEnvio:item.shipping_total,
                            comprobante:item.invoice,
                            origen:item.source_name=='WOOCOMMERCE'?'Shop':item.source_name,
                            origenId:item.origin_id
                        }
                        if(item.product_name){
                            linea = {...linea,
                                producto:item.product_name,
                                sku:'=""' + item.product_sku.toString() + '""',
                                cantidad:item.order_line_qty,
                                precio:item.order_line_price
                            }
                        }
                        return linea;
                });


                setDataCSV(datos);
                //setDataCSV(response.data.results);
                setLoading(false);
                csvRef.current.link.click();
            }).catch(() => {
                setLoading(false);
            });
        }
    }

    return (
        <>

            <button type="button" onClick={getWaybills} className="btn btn-success float-right m-2"> <i className="fa fa-file-excel"></i>
            Exportar CSV
            </button> 

            <CSVLink
                data={dataCSV}
                filename={"ReporteDespacho_" + moment().format("YYYYMMDD") + ".csv"}
                target="_blank"
                ref={csvRef}
            >
            </CSVLink>
        </>

            // <span role="button" className="dropdown-item" onClick={getOrders}>
            //     Exportar CSV
            // </span>
            //     <CSVLink
            //         data={dataCSV}
            //         filename={"Ordenes.csv"}
            //         target="_blank"
            //         ref={csvRef}
            //     >
            //     </CSVLink>
      );
}























