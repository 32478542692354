// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage, sortCaret } from "../../../../../../_metronic/_helpers";
import moment from 'moment';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect } from "react";
import * as laboratoriesCrud from '../../../_redux/laboratoriesCrud'
import * as storageHelpers from '../../../../../../_metronic/_helpers/LocalStorageHelpers'
import { errorMsg, successMsg } from "../../../../Global/helpers";
// import { PreciosDialog } from "../PreciosDialog";
import { useInventoryContext } from "../../../InventoryContext";
import { LaboratoryDetailsDialog } from "./LaboratoryDetailsDialog";

const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
];

const numberFormat = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' })


export function LaboratoryList({viewLaboProducts, queryParams, setQueryParams, search, setSearch}) {
    const [entities, setEntities] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [hideFecha2, setHideFecha2] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showDetails, setShowDetails] = useState(false);
    const [laboratoryDetails, setLaboratoryDetails] = useState([]);

    const inventoryContext = useInventoryContext();
    const{ selectedIds, setSelectedIds, loading, setLoading} = inventoryContext;
    const firstLoadRef = useRef(true);

    const fetchLabo = async()=>{
        try{
          // console.log(`fetchLabo -- queryParams:${JSON.stringify(queryParams)}`)
          setLoading(true);

          const _laboratorios = await laboratoriesCrud.getLaboAll(queryParams);

          if(_laboratorios && _laboratorios.data && _laboratorios.data.results){
              let labo = _laboratorios.data.results;
              let muestraFecha2 = labo.filter(i=>i.variacion_fecha2_prom!=null) ;

              if(muestraFecha2.length>0){
                setHideFecha2(false)
              }
              else{
                setHideFecha2(true)
              }

              setEntities(labo.map((reg, idx)=>({...reg, idx})));
              setTotalCount(+_laboratorios.data.metadata.count);
              
              setCurrentPage(1)
          }
          setSearch(false);
          setLoading(false);
          firstLoadRef.current = false; 
        }
        catch(err){
          setLoading(false)
          setSearch(false);
        }
    }

    const fetchLaboDetails = async(row)=>{
        try{
          // console.log(`fetchLabo -- queryParams:${JSON.stringify(queryParams)}`)
          setLoading(true);

          const _details = await laboratoriesCrud.getLaboDetails(row.lab_id);
          if(_details && _details.data && _details.data.results){
              let labo = _details.data.results;
              setLaboratoryDetails(labo
                  .map((reg, idx)=>({...reg, idx}))
                  .map(reg=>({...reg, lab_name:row.lab_name}))
                  );
          }
          setLoading(false);
          setShowDetails(true);
        }
        catch(err){
          setLoading(false)
        }
    }

    
    const viewLaboDetails = (row)=>{
      fetchLaboDetails(row)
    }

    useEffect(()=>{
        let _memVars = JSON.parse(storageHelpers.getStorage("FilterLaboratorios"));
        if(_memVars){
            const filter_exists = Object.values(_memVars).filter(d=>d!="" && d!=null && d!=undefined);
            const query_exists = Object.values(queryParams.filter).filter(d=>d!="" && d!=null && d!=undefined);
          
            
            console.log(`Effect QueryParams -- firstRender:${firstLoadRef.current}`)
            console.log(`_memVars:${_memVars}`)
            console.log(`filter_exists:${filter_exists}`)
            console.log(`queryParams:${JSON.stringify(queryParams)}`)
            console.log(`query_exists:${query_exists}`)
    
            const firstRender = firstLoadRef.current;
    
            if(filter_exists.length>0 && query_exists.length>0 && firstRender){
                firstLoadRef.current = false;            
                fetchLabo();
            }

        }

               
    }, [queryParams])

    useEffect(()=>{
      if(search){
        fetchLabo();
      }
    }, [search])

    useEffect(()=>{
        setTotalCount(0);
        setSearch(false);
    }, [])


    const columns = [
        {
            dataField: "lab_name",
            text:"Nombre",
            sort: true,
            sortCaret: sortCaret,
        },  
        {
            dataField: "cantidad_art",
            text:"Artículos",
            sort: true,
            sortCaret: sortCaret,
            headerClasses: 'text-right',
            style: { textAlign: 'right' },
        },    
        {
            dataField: "variacion_prom",
            text:"Última Var.",
            headerClasses: 'text-right',
            style: { textAlign: 'right' },
            formatter:(cell, row) => (<span>{cell?cell.toFixed(2) + '%' : ''}</span>)
        },      
        {
            dataField: "variacion_fecha2_prom",
            text:"Var. entre Fechas",
            headerClasses: 'text-right',
            hidden:hideFecha2,
            style: { textAlign: 'right' },
            formatter:(cell, row) => (<span>{cell?cell.toFixed(2) + '%' : ''}</span>)
        },  
        {
          dataField: "acciones",
          text: "",
          dummyField: true,
          classes:"text-right",
          headerClasses: "text-center",
          headerStyle: () => {
            return { minWidth: '120px' };
            },
          formatter: (cell, row)=>{
              return (
                <>
                  <OverlayTrigger overlay={<Tooltip id="mov-detail-tooltip">Ver Productos de {row.lab_name}</Tooltip>} >
                      <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1" onClick={() => {viewLaboProducts(row)}} > <span><i className="far fa-list-alt text-primary"></i></span> </a>
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip id="mov-detail-tooltip">Detalle</Tooltip>} >
                      <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1" onClick={() => {viewLaboDetails(row)}} > <span><i className="far fa-eye text-primary"></i></span> </a>
                  </OverlayTrigger>
                </>
            )
          }
        },

    ];
  
    function handlePageChange(page, sizePerPage){
      setCurrentPage(page)
      setPageSize(sizePerPage)        
    }
    function handleSizePerPageChange(sizePerPage){
      setCurrentPage(1)
      setPageSize(sizePerPage)        
    }

    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: sizePerPageList,
        sizePerPage: pageSize,
        page: currentPage,
        onPageChange:handlePageChange,
        onSizePerPageChange:handleSizePerPageChange,
        paginationTotalRenderer: (from, to, size) => (
          <span className="react-bootstrap-table-pagination-total">
            Mostrando { from } a { to } de { size } Resultados
          </span>
        )
      };    


    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selectColumnPosition: 'left',
        hideSelectAll: true,
        //selectColumnStyle: { cursor: 'pointer' },
        bgColor: '#C9F7F5',
        selected:selectedIds,
        onSelect: (row, isSelect, rowIndex, e) => {
          if (true) {
            if(isSelect){
              setSelectedIds([...selectedIds, row.reg]);
                
            }
            else{
              const newArr = selectedIds.filter(item=>item!=row.reg);
              setSelectedIds(newArr)
            }
          }
        },
    
      };


  return (
    <>
        {/* <PreciosDialog show={showPrecios} onHide={()=>setShowPrecios(false)} medicamento={medicamento}/> */}
        
        <LaboratoryDetailsDialog show={showDetails} onHide={()=>setShowDetails(false)} laboratoryDetails={laboratoryDetails} />
        
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
                return (
                <>
                  <Pagination paginationProps={paginationProps} >

                      <BootstrapTable
                          wrapperClasses="table-responsive"
                          classes="table table-head-custom table-vertical-center overflow-hidden"
                          bootstrap4
                          condensed
                          // remote 
                          keyField="idx"
                          data={ entities === null ? [] : entities }
                          columns={ columns }
                          bordered={false}  
                          // selectRow={ selectRow } 
                          // onTableChange={ getHandlerTableChange( setQueryParams) }
                          {...paginationTableProps}
                          >
                          <PleaseWaitMessage entities={entities} />                      
                          <NoRecordsFoundMessage entities={entities} />
                      </BootstrapTable>
                  </Pagination>
                </>
            );
            }}
        </PaginationProvider>


    </>
  );
}

















