import React from "react";

export const SourceColumnFormatter = (cellContent, row) => (
  <span style={{  }}>

    <p className="mb-0">{ cellContent?(cellContent=='WOOCOMMERCE'?('SHOP-' + row.origin_id) : (cellContent + ' -' + row.origin_id)):'' }</p>
    <p className="mb-0">{ row.billing.invoice }</p>  

  </span>
);
