import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { PharmaAccountPage } from "./account/PharmaAccountPage";

import {  PharmaDeliveriesPage } from "./pharma-deliveries/PharmaDeliveriesPage";

export default function PharmacyPage() {
  return (

    <Suspense fallback={<LayoutSplashScreen />}>

        <Switch>
          {
            /* Redirect from eCommerce root URL to /orders */
            <Redirect
              exact={true}
              from="/farmacia"
              to="/farmacia/entregas"
            />
          }
      
          <ContentRoute path="/farmacia/entregas" component={PharmaDeliveriesPage} />
          <ContentRoute path="/farmacia/cierre" component={PharmaAccountPage} />



        </Switch>

    </Suspense>
  );
}
