import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import moment from "moment";
import * as ordersCrud from "../../../Sales/_redux/orders/ordersCrud";
import BootstrapTable from "react-bootstrap-table-next";

export const OrderPrintPage = React.forwardRef(({order}, ref) => {

    const getSubtotal = ()=>{
        if(order.lines.length){
          return parseFloat(order.total) - parseFloat(order.total_tax)
        }  
      }
    const getShippingCost = ()=>{
        return order?order.shipping.total:0;  
      }
    
      useEffect(()=>{

      },[])
    const columns = [
        {
          dataField: "qty",
          text: "Cantidad",
          footer: ""
        },
        {
          dataField: "product",
          text: "Producto",
          footer: ""
        },
        {
          dataField: "sku",
          text: "SKU",
          footer: ""
        },
        {
          dataField: "price",
          text: "Unitario",
          formatter: (cellContent, row, rowIndex)=> new Intl.NumberFormat('de-DE').format(Math.trunc(cellContent)),
          footer: (columnData, column, columnIndex) => { return ( 
            <>
              <div className="mt-10">Subtotal</div>
              <div className="mt-3">IVA</div>
              <div className="mt-3">Envío</div>
              <div className="mt-3" style={{fontSize:"1.3em"}}>Total</div>
            </> )  }
        },
        {
          dataField: "total",
          isDummyField: true,
          text: "Total",
          classes: 'text-right',
          headerClasses: 'text-right',
          formatter: (cellContent, row, rowIndex)=> new Intl.NumberFormat('de-DE').format(Math.trunc(+row.qty * +row.price)),
          footer: (columnData, column, columnIndex) => { 
            return ( 
              <>
                <div className="text-right font-weight-normal mt-10">{new Intl.NumberFormat('de-DE').format(getSubtotal())}</div>
                <div className="text-right font-weight-normal mt-3">{new Intl.NumberFormat('de-DE').format(order.total_tax)}</div>
                <div className="text-right font-weight-normal mt-3">{new Intl.NumberFormat('de-DE').format(getShippingCost())}</div>
                <div className="text-right mt-3" style={{fontSize:"1.3em"}}>{new Intl.NumberFormat('de-DE').format(order.total)}</div>
              </> 
              )  
          }
        },
    
      ];

    if(order && order.id){
        return (
            <>
    
                <div ref={ref} className="print-template" style={{margin:"50px 50px"}}>           
    
                    <div className="d-flex justify-content-between mb-10 ">
                        <img src={`${process.env.PUBLIC_URL}/media/logos/logo_shop.png`} width="400"></img>
                    
                        <span className="mt-25 mr-10">
                            <table style={{width:"100%"}}>
                                <tbody>
                                <tr><td className="display-4 text-right">Orden# <b>{order.id}</b></td></tr>
                                <tr><td className="text-right" style={{fontSize:"13pt"}}>Fecha: {moment(order.order_creation).format("DD/MM/YYYY HH:mm")}</td></tr>
                                <tr><td className="text-right" style={{fontSize:"13pt"}}>Impreso: {moment().format("DD/MM/YYYY HH:mm")}</td></tr>
                                </tbody>
                            </table>
                        </span>
        
                    </div>

                    <div className="form-group row mt-10">
                        <div className="col-4">
                            <table style={{width:"100%"}}>
                                <tbody>
                                <tr><td style={{width:"100px"}}>Origen</td><td>{order.source.name=='WOOCOMMERCE'?'SHOP':order.source.name}</td></tr>
                                <tr><td style={{width:"100px"}}>ID Origen</td><td>{order.origin_id}</td></tr>
                                <tr><td style={{width:"100px"}}>Tipo Pago</td><td>{order.payment_type.name.replace("Pago ", "")}</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <div className="form-group row mt-10">
                        <div className="col-4">
                            <h5 className="control-label" style={{fontSize:"1.2em"}}><b>Facturación</b></h5>
                            <p className="m-0">{order.billing.first_name + ' ' + order.billing.last_name }</p>
                            <p className="m-0">Comprobante: <b>{order.billing.invoice}</b></p>
                            <p className="m-0">cuit/cuil: {order.billing.tax.number} ({order.billing.tax.name} )</p>
                            <p className="m-0">email: {order.billing.email}</p>
                            <p className="m-0">TE: {order.billing.phone}</p>
                        </div>

                        <div className="col-4">
                            <h5 className="control-label" style={{fontSize:"1.2em"}}><b>Domicilio Cliente</b></h5>
                            
                            <p className="m-0"> {order.shipping.address?order.shipping.address.street:null} {order.shipping.address?order.shipping.address.street_number:null}  {order.shipping.address?order.shipping.address.floor:null}{order.shipping.address?order.shipping.address.apartment:null} </p>
                            <p className="m-0"> ({order.shipping.address?order.shipping.address.postal_code:null}) {order.shipping.address?order.shipping.address.locality_name:null}</p>
                            <p className="m-0"> {order.shipping.address?order.shipping.address.state_name:null}</p>
                        </div>
                        <div className="col-4">
                            <h5 className="control-label" style={{fontSize:"1.2em"}}><b>Envío Solicitado</b></h5>
                            <p className="m-0">{order.shipping.type.name} </p>
                            
                            { order.shipping && (order.shipping.type.id == 1 || order.shipping.type.id == 2)?
                            <>
                                <p className="m-0"> {order.shipping.address?order.shipping.address.street:null} {order.shipping.address?order.shipping.address.street_number:null}  {order.shipping.address?order.shipping.address.floor:null}{order.shipping.address?order.shipping.address.apartment:null} </p>
                                <p className="m-0"> ({order.shipping.address?order.shipping.address.postal_code:null}) {order.shipping.address?order.shipping.address.locality_name:null}</p>
                                <p className="m-0"> {order.shipping.address?order.shipping.address.state_name:null}</p>
                            </> : '' 
                            }
                            
                            { order.shipping && (order.shipping.type.id == 3)?
                            <>
                                <p className="m-0">Av. San Martín 3009 CABA</p>
                                <p className="mt-1">DNI retiro: <b>{order.shipping.shipping_method.document_id}</b> </p>
                            </> : '' 
                            }

                        </div>

                    </div>

                    <Row className="mb-20">
                        <Col>
                            <label>Notas del cliente</label>
                            <Form.Text   style={{lineHeight:"1", minHeight:"80px"}} disabled name="note" as="textarea" value={order.shipping.note}  className="form-control" />
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        classes="table table-head-custom table-vertical-center overflow-hidden"
                                        bordered={false}
                                        bootstrap4
                                        remote
                                        keyField="id"
                                        data={order.lines === null ? [] : order.lines}
                                        columns={columns}
                                    >
                            </BootstrapTable>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
    else{
        return (<></>)
    }

  });