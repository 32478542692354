import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import moment from "moment";

export const AccountPrintPage = React.forwardRef(({entities, from, to}, ref) => {
    const [subtotalOnline, setSubtotalOnline] = useState(0);
    const [subtotalEfectivo, setSubtotalEfectivo] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(()=>{
        //console.log(entities)
        const efectivo = entities.filter(item=>item.payment_type.name=='Pago Efectivo');
        const online = entities.filter(item=>item.payment_type.name=='Pago Online');

        setSubtotalEfectivo( efectivo.reduce((acc, item)=>acc + +item.total, +0));
        setSubtotalOnline( online.reduce((acc, item)=>acc + +item.total, +0));
        setTotal( +online.reduce((acc, item)=>acc + +item.total, +0) + +efectivo.reduce((acc, item)=>acc + +item.total, +0));

    }, [])
    
    

    return (
        <>

            <div ref={ref} className="print-template" >           


            <div className="d-flex justify-content-between mb-10 ">
                <img src={`${process.env.PUBLIC_URL}/media/logos/logo_shop.png`} width="400"></img>
            
                {/* <span className="display-4 mt-25 mr-10">Reporte cierre de caja</span> */}
                <span className="mt-25 mr-10">
                    <table style={{width:"100%"}}>
                        <tbody>
                        <tr><td className="display-4">Reporte cierre de caja</td></tr>
                        <tr><td className="text-right" style={{fontSize:"13pt"}}>Fecha: {moment().format("DD/MM/YYYY HH:mm")}</td></tr>
                        </tbody>
                    </table>
                </span>


            </div>
            <h5 className="ml-10"><b>Desde: </b> {from}</h5>
            
            <h5 className="ml-10 mb-5"><b>Hasta: </b> {to}</h5>

            <Table bordered>
                <thead>
                    <tr>
                            <th className="text-center">Pedido</th>
                            <th className="text-center">Cliente</th>
                            <th className="text-center">Entregado</th>
                            <th className="text-center">Factura</th>
                            <th className="text-center">Tipo Pago</th>
                            <th className="text-center" style={{width:"120px"}}>Monto ($)</th>
                    </tr>
                </thead>
                <tbody>
                    {entities && entities.map((row, index)=>(
                        

                            <tr key={row.id} >
                                <td className="text-center">{row.id}</td>
                                <td>{row.customer.last_name + ', ' + row.customer.first_name}</td>
                                <td className="text-center">{ moment(row.delivered_date).format("DD/MM/YYYY HH:mm")  }</td>
                                <td className="text-center">{ row.billing.invoice  }</td>
                                <td className="text-center">{row.payment_type.name}</td>
                                <td className="text-right" style={{width:"120px"}}>{row.total}</td>
                            </tr>
                    ))}
                </tbody>

            </Table>


            {entities && <Table className="mt-2" bordered={ false }>
                <tbody></tbody>
                <tfoot>
                    <tr>
                        <td colSpan={5} className="text-right" style={{border:"none"}}><b>Subtotal Pago Online</b></td>
                        <td className="text-right" style={{width:"120px", border:"none"}}><b>{subtotalOnline}</b></td>
                    </tr>
                    <tr>
                        <td colSpan={5} className="text-right" style={{border:"none"}}><b>Subtotal Pago Efectivo</b></td>
                        <td className="text-right" style={{width:"120px", border:"none"}}><b>{subtotalEfectivo}</b></td>
                    </tr>
                    <tr>
                        <td colSpan={5} className="text-right" style={{border:"none"}}><b>TOTAL</b></td>
                        <td className="text-right" style={{width:"120px", border:"none"}}><b>{total}</b></td>
                    </tr>
                </tfoot>
            </Table>}

            </div>
        </>
    );
  });