import * as ordersCrud from "./ordersCrud";
import { getStatusEntrega } from "./ordersHelpers";
import {ordersSlice, callTypes} from "./ordersSlice";

const {actions} = ordersSlice;


export const fetchOrders = queryParams => dispatch => {
  //console.log(queryParams);
    dispatch(actions.startCall({ callType: callTypes.list }));

    if(queryParams.filter.cancelled){
        return ordersCrud
        .getCancelledOrders(queryParams)
        .then(response => {
          const totalCount = response.data.metadata.count;
          // Agrego unos objetos con alias, para cuando en la tabla hacen un ordenamiento en las columnas origen, shipping type o domicilio
          const entities = response.data.results.map(o=> ({...o, s:{...o.source}, st:{...o.shipping.type}, ad:{...o.shipping.address}}));
          dispatch(actions.ordersFetched({ totalCount, entities }));
        })
        .catch(error => {
          
          error.clientMessage = "Can't find orders";
          dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
    }
    else{
        return ordersCrud
        .getOrders(queryParams)
        .then(response => {
          const totalCount = response.data.metadata.count;
          // Agrego unos objetos con alias, para cuando en la tabla hacen un ordenamiento en las columnas origen, shipping type o domicilio
          const entities = response.data.results.map(o=> ({...o, s:{...o.source}, st:{...o.shipping.type}, ad:{...o.shipping.address}}));
          dispatch(actions.ordersFetched({ totalCount, entities }));
        })
        .catch(error => {
          
          error.clientMessage = "Can't find orders";
          dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
    }

};



export const fetchOrder = id => dispatch => {
  
  if (!id) {
    return dispatch(actions.orderFetched({ orderForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return ordersCrud
    .getOrderById(id)
    .then(response => {
      const order = response.data.results;

      order.deliveryStatus = getStatusEntrega(order);

      dispatch(actions.orderFetched({ orderForEdit: order }));

    })
    .catch(error => {
      error.clientMessage = "Can't find order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrder = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return ordersCrud
    .deleteOrder(id)
    .then(response => {
      dispatch(actions.orderDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createOrder = orderForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return ordersCrud
    .createOrder(orderForCreation)
    .then(response => {
      const { order } = response.data;
      dispatch(actions.orderCreated({ order }));
    })
    .catch(error => {
      error.clientMessage = "Can't create order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOrder = order => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return ordersCrud
    .updateOrder(order)
    .then(() => {
      dispatch(actions.orderUpdated({ order }));
    })
    .catch(error => {
      error.clientMessage = "No puede actualizar la órden";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};



export const deleteOrders = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return ordersCrud
    .deleteOrders(ids)
    .then(() => {
      dispatch(actions.ordersDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete orders";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const addToWave = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  
  return ordersCrud
    .addToWave(params)
    .then(response => {
      dispatch(actions.addedToWave());
    })
    .catch(error => {
      error.clientMessage = "Can't create order shipp";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setPayment = (order,paid) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return ordersCrud
    .setPayment(order.id, paid)
    .then(() => {
      dispatch(actions.orderPaid());
      
    })
    .catch(error => {
      error.clientMessage = "No puede actualizar la órden";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const postInternalNote = obTransfer => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return ordersCrud
    .postInternalNote(obTransfer)
    .then(() => {
      dispatch(actions.notePosted());
    })
    .catch(error => {
      error.clientMessage = "No puede actualizar la órden";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const cancelOrder = (order_id, message) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return ordersCrud
    .cancelOrder(order_id, message)
    .then(() => {
      dispatch(actions.orderCancelled());      
    })
    .catch(error => {
      error.clientMessage = "No puede actualizar la órden";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const activateOrder = (order_id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return ordersCrud
    .activateOrder(order_id)
    .then(() => {
      dispatch(actions.orderCancelled());
      
    })
    .catch(error => {
      error.clientMessage = "No puede actualizar la órden";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const sendToPending = (order_id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return ordersCrud
    .sendToPending(order_id)
    .then(() => {
      dispatch(actions.orderPending());      
    })
    .catch(error => {
      console.log(error)
      error.clientMessage = "No puede actualizar la órden";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};