// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/customersActions";
import * as localStorageHelpers from '../../../../../_metronic/_helpers/LocalStorageHelpers';
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../CustomersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../CustomersUIContext";

import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const { ExportCSVButton } = CSVExport;



export function CustomersTable() {
  const [customers, setCustomers] = useState([])
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
    };
  }, [customersUIContext]);

  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.customers }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    customersUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersUIProps.queryParams, dispatch]);



  const MyExportCSV = (props) => {
  
    const handleClick = () => {
      // passing my custom data
      //props.onExport(products.filter(r => r.id > 2));
      props.onExport(customers);
    };
  
  
    return (
      <div>
        <button className="btn btn-success" onClick={ handleClick }><i className="fa fa-file-excel mr-2"></i>Exportar</button>
      </div>
    );
  };

  const history = useHistory();

  const getOrders = (tax_number)=>{
      // el parámetro data se agrega a history.location.state
      let actual = JSON.parse(localStorageHelpers.getStorage('FilterOrders')) 

      actual.filter =  { text:tax_number };

      localStorageHelpers.setStorage(
        'FilterOrders',
        JSON.stringify(actual)
      )
      
      history.push(`/logistica/ordenes`);
  }


  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "first_name",
      text: "Nombre",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "last_name",
      text: "Apellido",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "tax_number",
      text: "Documento",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    // {
    //   dataField: "type",
    //   text: "Tipo",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.TypeColumnFormatter,
    // },
    {
      dataField: "orders",
      text: "",
      dummyField: true,
      formatter: (cell, row)=>{
        return (
          <OverlayTrigger overlay={<Tooltip id="waves-print-tooltip">Ver Órdenes</Tooltip>} >
              <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1" onClick={() => getOrders(row.tax_number)} > <span><i className="fas fa-list-ul text-primary"></i></span> </a>
          </OverlayTrigger>
      )
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber,
  };
  return (
    <>

            


              <ToolkitProvider
                keyField="id"
                data={ entities === null ? [] : entities }
                columns={ columns }
                exportCSV={ { onlyExportFiltered: true, exportAll: false } }
              >
                {
                  props =>
                    <div>
                      {/* <div className="text-right">
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <hr /> */}
                      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                        {({ paginationProps, paginationTableProps }) => {
                          return (
                              <Pagination isLoading={listLoading} paginationProps={paginationProps} >
                                <BootstrapTable { ...props.baseProps }
                                  wrapperClasses="table-responsive"
                                  bordered={false}
                                  classes="table table-head-custom table-vertical-center overflow-hidden"
                                  bootstrap4
                                  remote
                                  // keyField= {baseProps.keyField}
                                  // data={baseProps.data}
                                  // columns={baseProps.columns}
                                  defaultSorted={uiHelpers.defaultSorted}
                                  onTableChange={getHandlerTableChange(
                                    customersUIProps.setQueryParams
                                  )}
                                  // selectRow={getSelectRow({
                                  //   entities,
                                  //   ids: customersUIProps.ids,
                                  //   setIds: customersUIProps.setIds,
                                  // })}
                                  {...paginationTableProps}
                                >
                                  <PleaseWaitMessage entities={entities} />
                                  <NoRecordsFoundMessage entities={entities} />
                                </BootstrapTable>
                              </Pagination>          );
                        }}
                      </PaginationProvider>
                    </div>
                }
              </ToolkitProvider>



    </>
  );
}
