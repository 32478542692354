import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/Customers/_redux/customersSlice";
import {productsSlice} from "../app/modules/Sales/_redux/products/productsSlice";
import {ordersSlice} from "../app/modules/Sales/_redux/orders/ordersSlice";
import {wavesSlice} from "../app/modules/Sales/_redux/waves/wavesSlice";
import {printSlice} from "../app/modules/Sales/_redux/print/printSlice";
import {orderLinesSlice} from "../app/modules/Sales/_redux/orderLines/orderLinesSlice";
import {remarksSlice} from "../app/modules/Sales/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/Sales/_redux/specifications/specificationsSlice";
import { pageNumberSlice } from "../app/modules/Sales/_redux/tables/pageNumber";
import { waybillsSlice } from "../app/modules/Sales/_redux/waybills/waybillsSlice";
import { deliveriesSlice } from "../app/modules/Sales/_redux/deliveries/deliveriesSlice";
import { reportsSlice } from "../app/modules/Reports/_redux/reportsSlice";
import { pharmaDeliveriesSlice } from "../app/modules/Pharmacy/_redux/pharma-deliveries/pharmaDeliveriesSlice";
import { helpersSlice } from "../app/modules/Sales/_redux/helpers/helpersSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  orders: ordersSlice.reducer,
  waves: wavesSlice.reducer,
  waybills: waybillsSlice.reducer,
  deliveries: deliveriesSlice.reducer,
  reports: reportsSlice.reducer,
  helpers: helpersSlice.reducer,
  pharmaDeliveries: pharmaDeliveriesSlice.reducer,
  prints: printSlice.reducer,
  orderLines: orderLinesSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  pageNumber: pageNumberSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
