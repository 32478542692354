import React, {useMemo, useEffect, useState, useRef} from "react";
import { CSVLink } from "react-csv";
import * as ordersCrud from "../../../_redux/orders/ordersCrud";


export function ExportToCSV({queryParams}) {

    const [dataCSV, setDataCSV] = useState([]);
    const [loading, setLoading] = useState(false);
    const csvRef = useRef();
    
    const getOrders = ()=>{
        const newQueryParams = {...queryParams}
        newQueryParams.pageSize = null;

        if(!loading) {
            setLoading(true);
            ordersCrud.getOrders(newQueryParams).then((response) => {
                console.log(response.data.results);
                const orders = response.data.results.map(order => ({
                    id:order.id,
                    fechaPedido:order.order_creation,
                    comprobante:order.billing.invoice,
                    totalFacturado:order.total,
                    facturadoA: order.billing.last_name + ', ' + order.billing.first_name,
                    enviadoA: order.shipping.last_name + ', ' + order.shipping.first_name,
                    documento:order.billing.tax.number,
                    domicilio:order.shipping.address.street + ' ' + order.shipping.address.street_number + ' ' + order.shipping.address.floor + ' ' + order.shipping.address.apartment,
                    codigoPostal:order.shipping.address.postal_code,
                    localidad:order.shipping.address.locality_name,
                    provincia:order.shipping.address.state_name,
                    entregado:order.delivered_date?'Sí':'No',
                    origen: order.source.name=='WOOCOMMERCE'?'SHOP':'' ,
                    idOrigen:order.origin_id
                }));
                setDataCSV(orders);
                setLoading(false);
                csvRef.current.link.click();
            }).catch(() => {
                setLoading(false);
            });
          }
    }



    return (
        <>
            <span role="button" className="dropdown-item" onClick={getOrders}>
                Exportar CSV
            </span>
                <CSVLink
                    data={dataCSV}
                    filename={"Ordenes.csv"}
                    target="_blank"
                    ref={csvRef}
                >
                </CSVLink>
        </>
      );
}























