import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import * as storageHelpers from '../../../../../../_metronic/_helpers/LocalStorageHelpers'
import { useInventoryContext } from "../../../InventoryContext";
import { LaboratoryFilters } from "./LaboratoryFilters";
import { LaboratoryList } from "./LaboratoryList";
import * as alfabetaCrud from '../../../_redux/alfabetaCrud';
import moment from "moment";

const initialFilter = {
  filter: {    

  },
  sortOrder: undefined, 
  sortField: undefined,
  pageNumber: 1,
  pageSize: 10
};

export function LaboratoryListCard({ history }) {
  const [queryParams, setQueryParams] = useState(initialFilter);
  const [search, setSearch] = useState(false);
  const [lastProcessDate, setLastProcessDate] = useState("");

  const inventoryContext = useInventoryContext();
  const{ loading } = inventoryContext;


  const viewLaboProducts = (reg)=>{
      let _memVars = JSON.parse(storageHelpers.getStorage("FilterLaboratorios"));
      if(reg.lab_name){
        _memVars = {..._memVars, text:reg.lab_name}
        storageHelpers.setStorage(
          'FilterAlfabeta',
          JSON.stringify(_memVars))
      }


      history.push(`/inventario/alfabeta`);
  }

  const { user } = useSelector(
    (state) => ({ user: state.auth.user }),
    shallowEqual
  );


  const fetchLastProcessDate = async ()=>{
      const _date = await alfabetaCrud.getLastProcessDate();
      if(_date.data && _date.data.fecha_ultimo_proceso){
        setLastProcessDate(moment(_date.data.fecha_ultimo_proceso).format('YYYY/MM/DD HH:mm'))

      }
  }


  useEffect(()=>{
    fetchLastProcessDate();
  }, [])

  return (
    <Card>
      <Card.Header style={{paddingBottom:"0"}}>
          <Row>
              <Col>
                <div className="d-flex">
                  <h3 className="m-2">Laboratorios</h3>
                  {loading && (
                      <div className="overlay-layer bg-transparent mt-5 ml-5">
                        <div className="spinner spinner-lg spinner-primary" />
                      </div>
                  )}
                </div>
                <div className="ml-2">
                  <p style={{color:"#656565", textAlign:"end"}}>Última actualización: {lastProcessDate}</p>
                </div>
              </Col>
          </Row>
        

      </Card.Header>
      <Card.Body>

        
        <LaboratoryFilters setQueryParams={setQueryParams}  queryParams={queryParams} seacrh={search} setSearch={setSearch} lastProcessDate={lastProcessDate}/>

        {/* { (user.role.name=='ADMIN' || user.role.name=='INVENTARIO')  && 
              <DropdownButton id="dropdown-basic-button" title="Acciones">

                  <Dropdown.Item onClick={() => handleExport()} >Exportar a Excel</Dropdown.Item>
                  
              </DropdownButton>
        } */}


        <LaboratoryList viewLaboProducts={viewLaboProducts} queryParams={queryParams} setQueryParams={setQueryParams} search={search} setSearch={setSearch}/>

      </Card.Body>
    </Card>
  );
}
