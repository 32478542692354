// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import paginationFactory, {PaginationProvider,  } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";


export function PharmaAccountTable({entities}) {

        
    const dispatch = useDispatch();

    useEffect(()=>{
      
    },[])


    // Table columns
    const columns = [
        {
        dataField: "id",
        text: "ID Orden",
        },
        {
        dataField: "customer",
        text: "Cliente",
        //filter: textFilter(),
        formatter: (cellContent, row) => (
          <span><p>{ row.customer.last_name + ', ' + row.customer.first_name }</p>  </span>
          )
        },
        {
        dataField: "order_creation",
        text: "Pedido",
        formatter: (cellContent, row) => (
          <span><p>{ moment(cellContent).format("DD/MM/YYYY HH:mm")  }</p>  </span>
          )
        },
        {
        dataField: "delivered_date",
        text: "Entregado",
        formatter: (cellContent, row) => (
          <span><p>{ moment(cellContent).format("DD/MM/YYYY HH:mm")  }</p>  </span>
          )
        },
        {
        dataField: "billing.invoice",
        text: "Comprobante",
        //filter: textFilter(),
        },
        {
        dataField: "payment_type.name",
        text: "Tipo de Pago",
        },
        {
        dataField: "total",
        text: "Monto",
        },

    ];
  

    return (
        <>

            <BootstrapTable 
                rowStyle={ { fontSize: "smaller" } }
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote 
                keyField="id"
                data={ entities === null ? [] : entities }
                columns={ columns }
                bordered={false}
                condensed={true}
            >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
            </BootstrapTable>
 
        </>
  );
}
