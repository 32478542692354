import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Pagination } from "../../../../../../_metronic/_partials/controls";

const numberFormat = new Intl.NumberFormat('es-AR', {minimumFractionDigits:2})
const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
];

export function LaboratoryDetailsDialog({show, onHide, laboratoryDetails}) {
    const [entities, setEntities] =useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(()=>{
        if(laboratoryDetails){
            setEntities(laboratoryDetails)
            setTotalCount(laboratoryDetails.length)
            setCurrentPage(1)
        }
    }, [laboratoryDetails])

    const columns = [
        {
          dataField: "cantidad_art",
          text: "Cantidad Artículos",
          classes:"text-center",
          headerClasses: "text-center",
        },
        {
          dataField: "dias",
          text: "Días",
          classes:"text-center",
          headerClasses: "text-center",
        },
        {
          dataField: "variacion",
          text: "Variación",
          classes:"text-center",
          headerClasses: "text-center",
          formatter: (cellContent, row) => (
            <span>
              <p style={{ marginBottom:'0'}}><b>{ cellContent? numberFormat.format(cellContent) + '%':"" }</b></p>
            </span>
            )
        },
      ];

      function handlePageChange(page, sizePerPage){
        setCurrentPage(page)
        setPageSize(sizePerPage)        
      }
      function handleSizePerPageChange(sizePerPage){
        setCurrentPage(1)
        setPageSize(sizePerPage)        
      }
  
      const paginationOptions = {
          custom: true,
          totalSize: totalCount,
          sizePerPageList: sizePerPageList,
          sizePerPage: pageSize,
          page: currentPage,
          onPageChange:handlePageChange,
          onSizePerPageChange:handleSizePerPageChange,
          paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Mostrando { from } a { to } de { size } Resultados
            </span>
          )
        }; 

        return (
            <>

                <Modal show={show} onHide={onHide}  backdrop='static' size="lg">
                    <Modal.Header>
                        <Modal.Title >{laboratoryDetails.length ? laboratoryDetails[0].lab_name + ' - Detalle variaciones de precio' : '' }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="overlay overlay-block cursor-default">
                        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                            {({ paginationProps, paginationTableProps }) => {
                                return (
                                <>
                                <Pagination paginationProps={paginationProps} >

                                <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        classes="table table-head-custom table-vertical-center overflow-hidden"
                                        bootstrap4
                                        bordered={false}
                                        //striped={true}
                                        //remote
                                        keyField="idx"
                                        data={ entities || []}
                                        columns={columns}
                                        {...paginationTableProps}
                                    >
                                </BootstrapTable>
                                </Pagination>
                                </>
                            );
                            }}
                        </PaginationProvider>                
                    </Modal.Body>
                    <Modal.Footer>

                    <button type="button" onClick={onHide} className="btn btn-light btn-elevate" >
                        Cerrar
                    </button>

                    </Modal.Footer>
                </Modal>

            </>
        );
}
