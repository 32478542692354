import axios from "axios";

export const LABORATORIES_URL =`${process.env.REACT_APP_API_URL}/ab/lab`;
export const LABORATORIES_ALL_URL =`${process.env.REACT_APP_API_URL}/ab/lab-all`;

let cancelToken;

export function getLabo(params) {
    return (async () => {
        try {
            if (typeof cancelToken != typeof undefined) {
              cancelToken.cancel("Cancelado por new Request.")
            }
            cancelToken = axios.CancelToken.source();

            const resp = await axios.get(LABORATORIES_URL, { params, cancelToken: cancelToken.token });

            return resp;

        } catch (err) {
          
            if (axios.isCancel(err)) {
              //console.log('Request canceled');
            } else {
              // handle error
            }
            throw err;
        }
    })();
}

export function getLaboAll(params) {
  return (async () => {
      try {
          if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Cancelado por new Request.")
          }
          cancelToken = axios.CancelToken.source();
//           console.log(params)
//           const {pageNumber, pageSize, ... lastingParams} = params;
//  console.log(lastingParams)
          const resp = await axios.get(LABORATORIES_ALL_URL, { params, cancelToken: cancelToken.token });

          return resp;

      } catch (err) {
        
          if (axios.isCancel(err)) {
            //console.log('Request canceled');
          } else {
            // handle error
          }
          throw err;
      }
  })();
}

export function getLaboDetails(id) {
  return (async () => {
      try {
          const resp = await axios.get(`${LABORATORIES_URL}/${id}`);

          return resp;

      } catch (err) {

          throw err;
      }
  })();
}

export function getProductById(id) {
  return axios.get(`${LABORATORIES_URL}/${id}`);
}



export function createProduct(product){
  return axios.post(`${LABORATORIES_URL}`,  product );
}


export function updateProduct(product){
  return axios.put(`${LABORATORIES_URL}/${product.id}`,  product );
}

export function setArticle(data){
  return axios.post(`${LABORATORIES_URL}/${data.id}/set-article`,  {reg:data.reg} );
}

export function unsetArticle(product_id){
  return axios.post(`${LABORATORIES_URL}/${product_id}/unset-article` );
}

