import axios from "axios";
import * as localStorageHelpers from '../../../../_metronic/_helpers/LocalStorageHelpers';
// export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
// export const LOGIN_URL = 'http://mydomain.com/api/auth/login';
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/login`;

export function login(email, password) {
  var usuario = {
    email: email,
    password: password,
    ftoken: ""
  }
  
  localStorageHelpers.removeStorage("FilterOrders");
  localStorageHelpers.setStorage(
    'FilterOrders',
    JSON.stringify({
        filter: {
          logistic_state_id:"1"
        },
        sortOrder: "desc",
        sortField: "id",
        pageNumber: 1,
        pageSize: 20
    })
  )
  // usuario = {
  //   "usuario": "admin",
  //   "password": "81dc9bdb52d04dc20036dbd8313ed055"
  // };
  return axios.post(LOGIN_URL, usuario);
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
