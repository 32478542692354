import React from "react";
import { Switch } from "react-router-dom";
import { ContentRoute } from "../../../../../_metronic/layout";
// import { PrintLoadingDialog } from "../waves/waves-loading-dialog/PrintLoadingDialog";
import { PharmaDeliveriesCard } from "./PharmaDeliveriesCard";
import { PharmaDeliveriesUIProvider } from "./PharmaDeliveriesUIContext";
                  
export function PharmaDeliveriesPage({ history }) {


  const pharmaDeliveriesUIEvents = {

    openEditOrderPage: (id) => {
      history.push(`/logistica/ordenes/${id}/edit`);
    }, 
    
  };

  return (
    <PharmaDeliveriesUIProvider pharmaDeliveriesUIEvents={pharmaDeliveriesUIEvents}>

      {/* <PrintLoadingDialog/> */}
      {/* <WavesLoadingDialog /> */}
      <Switch>
          {/* <ContentRoute path="/logistica/entregas/:id/edit" component={EditDeliveryPage} />
          <ContentRoute path="/logistica/entregas/nuevo" component={NewDeliveryPage} /> */}
          <ContentRoute component={PharmaDeliveriesCard} />
      </Switch>
      {/* <WavesCard /> */}

    </PharmaDeliveriesUIProvider>
  )
}