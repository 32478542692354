// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import paginationFactory, {PaginationProvider,  } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as uiHelpers from "../ReportsUIHelpers";
import { useReportsUIContext } from "../ReportsUIContext";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import * as reportsActions from '../../_redux/reportsActions'


export function WaybillReportTable() {

    const { listLoading, reportUpdated, totalCount, entities } = useSelector(
        (state) => ({
          listLoading: state.reports.listLoading,
          reportUpdated: state.reports.reportUpdated,
          totalCount: state.reports.report.totalCount,
          entities: state.reports.report.entities
        }),
        shallowEqual
    );

    const reportsUIContext = useReportsUIContext();
    const reportsUIProps = useMemo(() => {
      return {
        ids: reportsUIContext.ids,
        actualReportQueryParams: reportsUIContext.actualReportQueryParams,
        setActualReportQueryParams: reportsUIContext.setActualReportQueryParams,
        withDetails:reportsUIContext.withDetails
      };
    }, [reportsUIContext]);

    
    const dispatch = useDispatch();

    useEffect(()=>{
      /* Para que funcione el paginado, recargo cada vez que hay un cambio en queryParams.
        Le pongo el if(entities) para que no dispare inicialmente  */
      if(entities && entities.length>0){
        dispatch(reportsActions.fetchReportsByName("waybills", reportsUIProps.actualReportQueryParams));
      }
      
    },[ reportsUIProps.actualReportQueryParams])


    useEffect(()=>{
      dispatch(reportsActions.reset())
    }, [dispatch])

    // Table columns
    const columns = [
        {
          dataField: "waybill_detail_id",
          text: "ID",
          hidden: true        
        },
        {
          dataField: "order_id",
          text: "ID Orden"   
        },
        {
        dataField: "customer_last_name",
        text: "Cliente",
        //filter: textFilter(),
        formatter: (cellContent, row) => (
          <span>
            <p style={{ marginBottom:'0' }}>{ row.customer_last_name + ', ' + row.customer_first_name }</p>  
            <p style={{ marginBottom:'0' }}>DNI: { row.billing_tax_number }</p>
            <p style={{ marginBottom:'0' }}>ID Cliente Shop: { row.customer_origin_id }</p>
            <p style={{ marginBottom:'0' }}>DBT: { row.customer_dbt_type_desc }</p>
          </span>
          )
        },
        {
        dataField: "invoice",
        text: "Comprobante",
        formatter: (cellContent, row) => (
          <span>
            <p style={{ marginBottom:'0' }}>{ cellContent }</p>
            <p style={{ marginBottom:'0' }}>{ (row.source_name=='WOOCOMMERCE'?'Shop':row.source_name) + ' ' + row.origin_id }</p>
          </span>
          )
        },
        {
        dataField: "product_name",
        text: "Producto",
        hidden:!reportsUIProps.withDetails,
        //filter: textFilter(),

        },
        {
        dataField: "order_line_qty",
        text: "Cantidad",
        classes:"text-center",
        hidden:!reportsUIProps.withDetails,
        //filter: textFilter(),

        },
        {
        dataField: "order_creation",
        text: "Pedido",
        hidden:reportsUIProps.withDetails,
        //filter: textFilter(),
        formatter: (cellContent, row) => (
          <span>
            <p style={{ marginBottom:'0' }}>{ moment(cellContent).format("DD/MM/YYYY") }</p>
            <p style={{ marginBottom:'0' }} className="text-muted">{ moment(cellContent).format("HH:mm") }</p>
          </span>
          )
        },
        {
        dataField: "dispatched_date",
        text: "Despacho",
        hidden:reportsUIProps.withDetails,
        //filter: textFilter(),
        formatter: (cellContent, row) => (
          <span>
            {/* <p style={{ marginBottom:'0' }}>ID: <b>{row.waybill_id}</b></p> */}
            <p style={{ marginBottom:'0' }}>{ moment(cellContent).format("DD/MM/YYYY") }</p>
            <p style={{ marginBottom:'0' }} className="text-muted">{ moment(cellContent).format("HH:mm") }</p>
          </span>
          )
        },
        {
        dataField: "delivered_date",
        text: "Entrega",
        //filter: textFilter(),
        hidden:reportsUIProps.withDetails,
        formatter: (cellContent, row) => (
          <span>
            <p style={{ marginBottom:'0' }}>{ cellContent ? moment(cellContent).format("DD/MM/YYYY") : '' }</p>
            <p style={{ marginBottom:'0' }} className="text-muted">{ cellContent? moment(cellContent).format("HH:mm") : '' }</p>
          </span>
          )
        },
        {
        dataField: "scs_name",
        text: "Tipo",
        },
        {
        dataField: "sc_name",
        text: "Empresa",
        },
        {
        dataField: "locality_name",
        text: "Localidad",
        },
        {
        dataField: "shipping_total",
        text: "Envío ($)",
        },

    ];
  

    const paginationOptions = {
      custom: true,
      totalSize: totalCount,
      sizePerPageList: uiHelpers.sizePerPageList,
      sizePerPage: reportsUIProps.actualReportQueryParams.pageSize,
      page: reportsUIProps.actualReportQueryParams.pageNumber,
      paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Mostrando { from } a { to } de { size } Resultados
        </span>
      )
    };

    return (
        <>

          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => {
                return (

                  <Pagination
                    isLoading={listLoading}
                    paginationProps={paginationProps}
                  >

                    <BootstrapTable 
                      rowStyle={ { fontSize: "smaller" } }
                      wrapperClasses="table-responsive"
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                      remote 
                      // el campo idx está generado artificialmente en el fetch 
                      keyField="idx"
                      data={ entities === null ? [] : entities }
                      columns={ columns }
                      bordered={false}
                      condensed={true}
                      onTableChange={ getHandlerTableChange( reportsUIProps.setActualReportQueryParams) }
                      {...paginationTableProps}
                    >
                      <PleaseWaitMessage entities={entities} />
                      <NoRecordsFoundMessage entities={entities} />
                    </BootstrapTable>
                  </Pagination>

                );
              }}
          </PaginationProvider>
 
        </>
  );
}
