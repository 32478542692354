import React, { createContext, useContext, useState, useCallback, useSelector } from "react";
import { isEqual, isFunction } from "lodash";
import {initialFilter} from "./PharmaDeliveriesUIHelpers";

const PharmaDeliveriesUIContext = createContext();

export function usePharmaDeliveriesUIContext() {
  return useContext(PharmaDeliveriesUIContext);
}

export const deliveriesUIConsumer = PharmaDeliveriesUIContext.Consumer;

export function PharmaDeliveriesUIProvider({ pharmaDeliveriesUIEvents, children }) {

  const [ordersQueryParams, setOrdersQueryParamsBase] = useState( initialFilter );
  const [defaultView, setDefaultView] = useState( "Pendientes" );
  const [selectedIds, setSelectedIds] = useState([]);

  const setOrdersQueryParams = useCallback((nextQueryParams) => {
    
    setOrdersQueryParamsBase((prevQueryParams) => {

        if (isFunction(nextQueryParams)) {
          nextQueryParams = nextQueryParams(prevQueryParams);
        }

        if (isEqual(prevQueryParams, nextQueryParams)) {
          return prevQueryParams;
        }

        return nextQueryParams;

    });
  }, []);


  const value = {
    ordersQueryParams,
    setOrdersQueryParams,
    setOrdersQueryParamsBase,
    defaultView,
    setDefaultView,
    selectedIds,
    setSelectedIds,
    openEditOrderPage: pharmaDeliveriesUIEvents.openEditOrderPage,
  };

  return (
    <PharmaDeliveriesUIContext.Provider value={value}>
      {children}
    </PharmaDeliveriesUIContext.Provider>
  );
}
