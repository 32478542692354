

export const getStatusEntrega = (order) => {

    if(order.delivered_date){
        return {label:'Entregada', color:'success', labelUndo:'Deshacer Entrega'}
    }
    else if(order.returned_date){
        return {label:'Devuelta', color:'danger', labelUndo:'Deshacer Devolución'} 

    }
    // else if(order.returning_pending_date){
    //     return {label:'Pendiente Devolución', color:'danger', labelUndo:'Deshacer Devolución'} 
    // }

    if(order.tracking && order.tracking.length>1){
        return  {label:'Devuelta con redespacho', color:'danger', labelUndo:'Deshacer Devolución'}
    }

};

