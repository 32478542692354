import {createSlice} from "@reduxjs/toolkit";

const initialPrinterState = {
  listLoading: false,
  actionsLoading: false,
  printCreated: false,
  totalCount: 0,
  error: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const printSlice = createSlice({
        name: "prints",
        initialState: initialPrinterState,
        reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        resetError: (state, action) => {
            state.error = null;
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // Nueva Impresion Guia despacho
        printGuideCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.printCreated = !state.printCreated;
        },
        // Nueva Impresion Remito
        printConsolidatedCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.printCreated = !state.printCreated;
        },
        // Nueva Impresion Etiqueta
        printTicketCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.printCreated = !state.printCreated;
        },
        printWaybillCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.printCreated = !state.printCreated;
        },
        printDepotMovementCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.printCreated = !state.printCreated;
        },
    }
});

