import {createSlice} from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";

//const history = useHistory();

const initialWaybillsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  waybillForEdit: undefined,
  lastError: null,
  waybillUpdated: false,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const waybillsSlice = createSlice({
  name: "waybills",
  initialState: initialWaybillsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getWaybillById
    waybillFetched: (state, action) => {
      state.actionsLoading = false;
      state.waybillForEdit = action.payload.waybillForEdit;
      state.error = null;
    },
    // findWaybills
    waybillsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createWaybill
    waybillCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      //state.entities.push(action.payload.waybill);
      state.waybillUpdated=!state.waybillUpdated;
    },
    // updateWaybill
    waybillUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.waybill.id) {
          return action.payload.waybill;
        }
        return entity;
      });
    },
    // deleteWaybill
    waybillDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteWaybills
    waybillsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    deleteDispatched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.waybillUpdated = !state.waybillUpdated;
    },
    // waybillsUpdateState
    waybillsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    ordersAddedToWaybill: (state, action) => {
      console.log("DIspatched")
      state.waybillUpdated = !state.waybillUpdated;
      state.error = null;
    },
    waybillRollbacked: (state, action) => {
      state.waybillUpdated = !state.waybillUpdated;
      state.error = null;
    },
    orderReturned: (state, action) => {
      state.waybillUpdated = !state.waybillUpdated;
      state.error = null;
      
    },
    waybillDispatched: (state, action) => {
      state.waybillUpdated = !state.waybillUpdated;
      state.error = null;
      
    },    
  }
});
