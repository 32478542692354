import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage } from "../../../../../_metronic/_helpers";
import * as alfabetaCrud from '../../_redux/alfabetaCrud'
import "../../inventory.css";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import { Chart } from "react-google-charts";
import paginationFactory, {
    PaginationProvider,
  } from "react-bootstrap-table2-paginator";
import * as XLSX from 'xlsx/xlsx.mjs';

const numberFormat = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' })

const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
];

export function AlfabetaHistoricoCard({history}) {
    const { id } = useParams();
    const [medicamento, setMedicamento] = useState({});
    const [entities, setEntities] =useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] =useState([["Fecha", "Precio"]]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const options = {
        title: "Evolución del precio",
        hAxis: {format:Date},
        vAxis:{format:"$"}
      };

    const fetchData = async()=>{
        const res = await alfabetaCrud.getProductById({id})
        if(res && res.data && res.data.results){
            const med = res.data.results[0];
            const fechas =  med.precios.map(p => (moment(p.f_inicio).toDate()));
            const precios =  med.precios.map(p => (p.prc));
            const datos = {
                "Fecha":fechas,
                "Precio":precios
            }
            const arrIndices= Object.keys(datos);
            const arrValores= Object.values(datos);
            const arr = [];

            for(var i=0; i<arrValores[0].length; i++){
                arr[i]=arrValores.map(v=>{return v[i]})
            }
            arr.unshift(arrIndices);
            setData(arr);

            setTotalCount(med.precios.length);
            setEntities(med.precios.map((p, idx)=>({...p, idx:idx})));
            setMedicamento(med);
        }
    }
    const handleExport = async ()=>{
      try{
        const datos = entities.map(e=>({
          desde:e.f_inicio?moment(e.f_inicio).format('DD/MM/YYYY'):null,
          hasta:e.f_fin?moment(e.f_fin).format('DD/MM/YYYY'):null,
          precio_$:e.prc?parseFloat(e.prc).toFixed(2):null,
          variacion:e.variacion?parseFloat(e.variacion).toFixed(2):null
        }))
        var ws = XLSX.utils.json_to_sheet(datos);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Precios");
        XLSX.writeFile(wb, `${medicamento.nom}.xlsx`);
      }
      catch(err){
      }
    }
    useEffect(()=>{
        if(id)
            fetchData();
    }, [id])
  

    const columns = [
        {
          dataField: "f_inicio",
          text: "Fecha Desde",
          formatter: (cellContent, row) => (
            <span>
              <p style={{ marginBottom:'0' }}>{ cellContent?moment(cellContent).format("DD/MM/YYYY"):"" }</p>
            </span>
            )
        },
        {
          dataField: "f_fin",
          text: "Fecha Hasta",
          formatter: (cellContent, row) => (
            <span>
              <p style={{ marginBottom:'0' }}>{ cellContent?moment(cellContent).format("DD/MM/YYYY"):"" }</p>
            </span>
            )
        },
        {
          dataField: "precio_dias",
          text: "Días",

        },
        {
          dataField: "prc",
          text: "Precio",
          headerClasses: 'text-right',
          style: { textAlign: 'right' },
          formatter: (cellContent, row) => (
            <span>
              <p style={{ marginBottom:'0'}}><b>{ cellContent? numberFormat.format(cellContent):"" }</b></p>
            </span>
            )
        },
        {
          dataField: "variacion",
          text: "Variación",
          headerClasses: 'text-right',
          style: { textAlign: 'right' },
          formatter: (cellContent, row) => (
            <span>
              {/* {cellContent && cellContent > 5 && <p style={{ marginBottom:'0', color:'red'}}>{ cellContent? cellContent.toFixed(2) + '%' : "" }</p>}
              {cellContent && cellContent <= 5 && <p style={{ marginBottom:'0', color:'green'}}>{ cellContent? cellContent.toFixed(2) + '%' : "" }</p>} */}
              {cellContent && <p style={{ marginBottom:'0'}}>{ cellContent? cellContent.toFixed(2) + '%' : "" }</p>}
            </span>
            )
        },
        // {
        //     dataField: "dummy",
        //     dummyField:true,
        //     style: {
        //       minWidth: "70px",
        //     },
        //     formatter: (cellContent, row) => (
        //       <span>{  }</span>
        //       )

        // },
      ];


      function handlePageChange(page, sizePerPage){
        setCurrentPage(page)
        setPageSize(sizePerPage)        
      }
      function handleSizePerPageChange(sizePerPage){
        setCurrentPage(1)
        setPageSize(sizePerPage)        
      }
      const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: sizePerPageList,
        sizePerPage: pageSize,
        page:currentPage,
        onPageChange:handlePageChange,
        onSizePerPageChange:handleSizePerPageChange,
        paginationTotalRenderer: (from, to, size) => (
          <span className="react-bootstrap-table-pagination-total">
            Mostrando { from } a { to } de { size } Resultados
          </span>
        )
      };    


    return (
        <Card>
        <CardHeader title="">
            {medicamento && medicamento.nom &&<h4 className="mt-10"><b>{medicamento.nom}</b> <span style={{fontWeight: "200"}}>{'  ' + medicamento.pres}</span></h4>}
            <CardHeaderToolbar>
                <div className="text-right">
                    <button type="button" className="btn btn-success ml-2" onClick={()=>{history.goBack()}} >
                        <i className="fa fa-chevron-left"></i> Volver 
                    </button>
                    <button type="button" className="btn btn-primary ml-2" onClick={()=>{handleExport()}} >
                        <i className="fa fa-file-excel"></i> Exportar 
                    </button>
                </div>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>


            {data.length>1 && <Row>
                <Col>
                    <Chart
                        chartType= "LineChart"
                        data= {data}
                        options={options}
                        width={"100%"}
                        height={"400px"}
                    />
                </Col>
            </Row>}

            <Row>
                <Col>
                    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                        {({ paginationProps, paginationTableProps }) => {
                            return (
                            <>
                            <Pagination paginationProps={paginationProps} >

                                <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        classes="table table-head-custom table-vertical-center overflow-hidden"
                                        bootstrap4
                                        bordered={false}
                                        //striped={true}
                                        // remote
                                        keyField="idx"
                                        data={ entities || []}
                                        columns={columns}
                                        {...paginationTableProps}
                                        >
                                        <PleaseWaitMessage entities={entities} />                      
                                        <NoRecordsFoundMessage entities={entities} />
                                </BootstrapTable>
                            </Pagination>
                            </>
                        );
                        }}
                    </PaginationProvider>
                </Col>
            </Row>


        </CardBody>
        </Card>
    );
}

