import axios from "axios";


export const CUSTOMERS_URL =`${process.env.REACT_APP_API_URL}/customers`;
export const ORDERS_URL =`${process.env.REACT_APP_API_URL}/orders`;

// CREATE =>  POST: add a new customer to the server
export function createCustomer(customer) {
  return axios.post(CUSTOMERS_URL, { customer });
}

// READ
let cancelToken;

export function getAllCustomers(params) {
    return (async () => {
        try {
            if (typeof cancelToken != typeof undefined) {
              cancelToken.cancel("Cancelado por new Request.")
            }
            cancelToken = axios.CancelToken.source();

            const resp = await axios.get(CUSTOMERS_URL, { params, cancelToken: cancelToken.token });

            return resp;

        } catch (err) {
          
            if (axios.isCancel(err)) {
              //console.log('Request canceled');
            } else {
              // handle error
            }
            throw err;
        }
    })();
}


// export function getAllCustomers(params) {
//     return axios.get(CUSTOMERS_URL,{ params });
//   // return new Promise(resolve => {
//   //   const data = {
//   //     entities:[
//   //       {
//   //         id: 10,
//   //         firstName:"First",
//   //         lastName:"Last",
//   //         email:"un@mail.com",
//   //         gender:"Male",
//   //         status:"Active",
//   //         type:"Tipo"

//   //       },
//   //     ],
//   //     totalCount:1
//   //   };
//   //   setTimeout(() => {
//   //     resolve({data});
//   //   }, 10);
//   // });

// }

export function getCustomerById(customerId) {
  return axios.get(`${CUSTOMERS_URL}/${customerId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
  // return new Promise(resolve => {
  //   const data = {
  //     entities:[
  //       {
  //         id: 10,
  //         firstName:"First",
  //         lastName:"Last",
  //         email:"un@mail.com",
  //         gender:"Male",
  //         status:"Activo",
  //         type:"Tipo"

  //       },
  //     ],
  //     totalCount:1
  //   };
  //   setTimeout(() => {
  //     resolve(data);
  //   }, 10);
  //});
  
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer) {
  return axios.put(`${CUSTOMERS_URL}/${customer.id}`, { customer });
}

// UPDATE Status
export function updateStatusForCustomers(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForCustomers`, {
    ids,
    status
  });
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
  return axios.delete(`${CUSTOMERS_URL}/${customerId}`);
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteCustomers`, { ids });
}

