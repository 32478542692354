import React, { useMemo } from "react";
import { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { AlfabetaLinkDialog } from "./AlfabetaLinkDialog";
import { ProductsFilters } from "./ProductsFilters";
import { ProductsList } from "./ProductsList";


const initialFilter = {
  filter: {    

  },
  sortOrder: "asc", 
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};


export function ProductsListCard({history}) {
  const [queryParams, setQueryParams] = useState(initialFilter);

  const viewProduct = (row)=>{
      history.push(`/inventario/productos/${row.id}`);
  }

  return (
    <Card>
      <CardHeader title="Lista de Productos">
        <CardHeaderToolbar>
          {/* <button
            type="button"
            className="btn btn-primary"
            onClick={customersUIProps.newCustomerButtonClick}
          >
            New Customer
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ProductsFilters setQueryParams={setQueryParams}  queryParams={queryParams}/>
        <ProductsList viewProduct={viewProduct}   queryParams={queryParams} setQueryParams={setQueryParams}/>
      </CardBody>
    </Card>
  );
}
