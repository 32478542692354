
import React, { useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "lodash";
import * as storageHelpers from '../../../../../../_metronic/_helpers/LocalStorageHelpers'
import ReactDatePicker from "react-datepicker";
import "../../../inventory.css";
import moment from 'moment';

const initialFormValues={
  text:undefined,
  date1:undefined,
  date2:undefined,
  avgx:undefined,
  avgy:undefined,
  price_greater_than:undefined
}


const prepareFilter = (queryParams, values) => {
    const { text, date1, date2, avgx, avgy, price_greater_than } = values;

    const newQueryParams = { ...queryParams, pageNumber:1, pageSize:10, filter:{...queryParams?.filter || {}} };


    if (text && text!='') {
      newQueryParams.filter.text = text;
    }
    else{
      newQueryParams.filter.text = undefined;
    }
    if (date1 && date1!='') {
      newQueryParams.filter.date1 = date1;
    }
    else{
      newQueryParams.filter.date1 = undefined;
    }
    if (date2 && date2!='') {
      newQueryParams.filter.date2 = date2;
    }
    else{
      newQueryParams.filter.date2 = undefined;
    }

    if (avgx && avgx!='') {
      newQueryParams.filter.avgx = avgx;
    }
    else{
      newQueryParams.filter.avgx = undefined;
    }
    if (avgy && avgy!='') {
      newQueryParams.filter.avgy = avgy;
    }
    else{
      newQueryParams.filter.avgy = undefined;
    }
    if (price_greater_than && price_greater_than!='') {
      newQueryParams.filter.price_greater_than = price_greater_than;
    }
    else{
      newQueryParams.filter.price_greater_than = undefined;
    }
    return newQueryParams;
};


export function LaboratoryFilters({ queryParams, setQueryParams, search, setSearch, lastProcessDate }) {
    const [formDefaultValues, setFormDefaultValues] = useState({});

    const changePriceFilter = (e)=>{
        var reg = new RegExp(/^\d*$/);
        if(!reg.test(e.target.value)){
            return;
        }  
        setFormDefaultValues({...formDefaultValues, price_greater_than:e.target.value})
    }

    const handleKeyDown = (e)=>{
        if(e.key=='Enter'){
          storageHelpers.setStorage(
            'FilterLaboratorios',
            JSON.stringify(formDefaultValues))
          setSearch(true);
        }
    }

    const handleSearch=()=>{
        storageHelpers.setStorage(
          'FilterLaboratorios',
          JSON.stringify(formDefaultValues))
        setSearch(true);
    }

    useEffect(()=>{
        //console.log(`formDefaultValues: ${JSON.stringify(formDefaultValues)}`)
        if(Object.keys(formDefaultValues).length != 0){
            // const filter_exists = Object.values(formDefaultValues).filter(d=>d!="" && d!=null && d!=undefined);

            // if(filter_exists.length>0){

                let values = {
                  "text":formDefaultValues.text,
                  "date1":formDefaultValues.date1,
                  "date2":formDefaultValues.date2,
                  "avgx":+formDefaultValues.avgx,
                  "avgy":+formDefaultValues.avgy,
                  "price_greater_than":+formDefaultValues.price_greater_than
                }
    
                const newQueryParams = prepareFilter(queryParams, values);
    
                if (!isEqual(newQueryParams, queryParams)) {
                    setQueryParams(newQueryParams);
                    // storageHelpers.setStorage(
                    //     'FilterLaboratorios',
                    //     JSON.stringify(formDefaultValues))
                }
            // }
        }

    }, [formDefaultValues])

    useEffect(()=>{
        if((formDefaultValues.date1==null || formDefaultValues.date1==undefined) && 
              formDefaultValues.date2!=undefined && formDefaultValues.date2!=null){
            const {date2, ...withoutDate2} = formDefaultValues; 
            setFormDefaultValues(withoutDate2)
        }
    }, [formDefaultValues.date1])

    
    useEffect(()=>{
        let _memVars = JSON.parse(storageHelpers.getStorage("FilterLaboratorios"));
        if(_memVars){
          setFormDefaultValues({..._memVars, date1:_memVars.date1?moment(_memVars.date1).toDate():null, date2:_memVars.date2?moment(_memVars.date2).toDate():null});
        }
        else{
          setFormDefaultValues(initialFormValues);
        }
    }, [])

    return (
        <>
          <div className="form-group row">
            
            <div className="col-lg-6">
              <input
                type="text"
                className="form-control"
                name="text"
                placeholder="Search"
                value={formDefaultValues?.text || ""}
                onChange={e=>setFormDefaultValues({...formDefaultValues, text:e.target.value})}
                onKeyDown={(e) => handleKeyDown(e) }
              />
              <small className="form-text text-muted">
                Buscar por <b>Nombre, Monodroga o Laboratorio</b>
              </small>
            </div>
            
            <div className="col-lg-3 customDatePickerWidth">
              <ReactDatePicker
                      autoComplete='off'
                      className="form-control"
                      style={{ width: "100%" }}
                      name="date2"
                      selected={formDefaultValues.date2}
                      maxDate={formDefaultValues.date1}
                      dateFormat="dd/MM/yyyy"
                      disabled={!formDefaultValues.date1}
                      onChange={(date) => {
                        setFormDefaultValues({...formDefaultValues, date2: date});
                      }}
                />
                <small className="form-text text-muted">
                  Fecha <b>Desde</b>
                </small>
            </div>

            <div className="col-lg-3 customDatePickerWidth" >
              <ReactDatePicker
                      autoComplete='off'
                      className="form-control"
                      style={{ width: "100%" }}
                      name="date1"
                      selected={formDefaultValues.date1}
                      minDate={formDefaultValues.date2}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        setFormDefaultValues({...formDefaultValues, date1: date});
                      }}
                />
                <small className="form-text text-muted">
                  Fecha <b>Hasta</b>
                </small>
            </div>

          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <input
                  type="text"
                  className="form-control"
                  name="avgx"
                  placeholder="Search"
                  value={formDefaultValues?.avgx || ''}
                  onChange={(e) => {
                    setFormDefaultValues({...formDefaultValues, avgx: e.target.value});
                  }}
                  onKeyDown={(e) => handleKeyDown(e) }
                />
                <small className="form-text text-muted">
                  Cantidad de cambios a promediar
              </small>
            </div>
            <div className="col-lg-3">
              <input
                  type="text"
                  className="form-control"
                  name="avgy"
                  placeholder="Search"
                  value={formDefaultValues?.avgy || ''}
                  onChange={(e) => {
                    setFormDefaultValues({...formDefaultValues, avgy: e.target.value});
                  }}
                  onKeyDown={(e) => handleKeyDown(e) }
                />
                <small className="form-text text-muted">
                  Días para próximo cambio
              </small>
            </div>
            <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="text"
                  placeholder="Search"
                  value={formDefaultValues?.price_greater_than || ""}
                  onChange={changePriceFilter}
                  onKeyDown={(e) => handleKeyDown(e) }
                />
                <small className="form-text text-muted">
                  Precio <b>mayor a</b>
                </small>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-12 text-right">
                {/* { 
                  (formDefaultValues.text || formDefaultValues.date1 || formDefaultValues.date2 || formDefaultValues.avgx 
                      || formDefaultValues.avgy || formDefaultValues.price_greater_than) &&  */}

                  <button type="button" onClick={()=>{handleSearch()}} className="btn btn-primary mb-5 cursor-pointer"> <i className="fa fa-search"></i>
                    Buscar
                  </button>
                {/* } */}
            </div>
          </div>
        </>
    );
}



