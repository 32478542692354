import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, InputGroup, Modal, Row, } from "react-bootstrap";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { errorMsg, successMsg } from "../../../../Global/helpers";
import * as alfabetaCrud from '../../../_redux/alfabetaCrud';
import * as productsCrud from '../../../_redux/productsCrud';


const initialAddress = {
    state_id: null,
    locality_id: null,
    street: "",
    postal_code: "",
    street_number: "",
    floor: "",
    apartment: ""
    
}


export function AlfabetaLinkDialog({ show, onHide, product, refresh, setRefresh }) {

    const [options, setOptions] = useState([]);
    const [showError, setShowError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loadingAlfabeta, setLoadingAlfabeta] = useState(false)
    const [singleSelections, setSingleSelections] = useState([]);

    const handleSubmit = async (e)=>{
        try{
            e.preventDefault();
            if(singleSelections.length<1){
                return;
            }
            const reg = singleSelections[0].reg;
            await productsCrud.setArticle({...product, reg})
            successMsg("Registro actualizado correctamente");
            onHide();
            setRefresh(!refresh);
        }
        catch(err){
            console.log(err);
            errorMsg("Error al actualizar el registro");
        }

    }

    const handleSearch = async(query) => {
        try{
            setLoadingAlfabeta(true);    
            const op = await alfabetaCrud.getProductsMin({ filter:{text:query}})
            if(op && op.data && op.data.results)
                setOptions(op.data.results)
            else
                setOptions([])
            setLoadingAlfabeta(false)
    
        }
        catch(err){
            console.log(err)
            setLoadingAlfabeta(false)

        }
      };


    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="example-modal-sizes-title-lg"
                size="lg"
                backdrop='static'
            >
                
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-md">
                    Vincular a producto alfabeta
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    {showError && <Alert variant="danger" onClose={()=>setShowError(false)} dismissible> Error al insertar nuevo domicilio </Alert>}

                    <Form onSubmit={handleSubmit} noValidate   validated={validated}>

                        <Form.Group>
                                <AsyncTypeahead
                                    id="buscadorLinkAlfabeta"
                                    className='mr-6'
                                    isLoading={loadingAlfabeta}
                                    minLength={3}
                                    labelKey={option => `${option.nombre_completo}`}
                                    onSearch={handleSearch}
                                    options={options}
                                    filterBy={['nom', 'nombre_completo', 'lab.name', 'monodroga']}
                                    prompText='Escribe para buscar medicamentos'
                                    searchText='Buscando...'
                                    placeholder='Escribe para buscar medicamentos'
                                    onChange={setSingleSelections}
                                />

                        </Form.Group>

                        <div className="text-right mt-10">
                            <Button variant="default" type="button" onClick={()=>onHide()} className="mr-2">
                                Cancelar
                            </Button>
                            <Button variant="primary" type="submit">
                                Guardar
                            </Button>
                        </div>

                    </Form>

                </Modal.Body>
            </Modal>

        </>
    );
}
