import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import { PharmaAccountFilter } from "./PharmaAccountFilter";
import { PharmaAccountTable } from "./PharmaAccountTable";

                  
export function PharmaAccountCard() {
    const [entities, setEntities] = useState([]);

    return (
        <Card>
        <CardHeader title="Cierre de Caja">

            <CardHeaderToolbar>
            
            </CardHeaderToolbar>

        </CardHeader>
        <CardBody>

            <PharmaAccountFilter setEntities={setEntities} entities={entities}/>
            
            <PharmaAccountTable entities={entities} />

        </CardBody>
        </Card>

    )
}