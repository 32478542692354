// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { getHandlerTableChange } from "../../../../../../_metronic/_helpers";
import moment from 'moment';
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect } from "react";
import * as productsCrud from '../../../_redux/productsCrud'
import { AlfabetaLinkDialog } from "./AlfabetaLinkDialog";
import { successMsg } from "../../../../Global/helpers";

const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
];

const numberFormat = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' })

export function ProductsList({queryParams, setQueryParams, viewProduct}) {
    const [entities, setEntities] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
        const [showAlfabetaLink, setShowAlfabetaLink] = useState(false);
    const [product, setProduct] = useState(false);
    const [refresh, setRefresh] = useState(false);


    const onHide = ()=>{
      setShowAlfabetaLink(false);
    }

    
    const fetchProducts = async()=>{
        const _products = await productsCrud.getProducts(queryParams);
        if(_products && _products.data && _products.data.results){
            setEntities(_products.data.results)
            setTotalCount(+_products.data.metadata.count);
        }
    }


    const setAlfabetaLink = (row)=>{
      setProduct(row);
      setShowAlfabetaLink(true);
    }

    const unsetAlfabetaLink = (row)=>{

      Swal.fire({
        title: 'Confirmación',
        text: `Confirma la devinculación del producto AB?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        confirmButtonColor: '#0A9E95',
        cancelButtonText:'No'
        }).then(async res=>{
            if (res.isConfirmed) {
                await productsCrud.unsetArticle(row.id);
                successMsg(`Artículo desvinculado correctamente`);
                //setRefresh(!refresh);
                await fetchProducts();
            } 
        })
    }

    useEffect(()=>{
        fetchProducts();
    }, [queryParams, refresh])

    const columns = [
        {
            dataField: "id",
            text:"ID",
            // formatter: (  cellContent, row, rowIndex) => (
            //     <a onClick={() => openEditProfesionPage(row)} className="text-primary"> {cellContent}</a>
            // )
        },
        {
            dataField: "name",
            text:"Producto"
        },  
        {
            dataField: "sku",
            text:"SKU"
        },  
        {
            dataField: "price",
            text:"Precio",
            headerClasses: 'text-right',
            style: { textAlign: 'right' },
            formatter:(cell)=>{
              return (
                <span>{cell?numberFormat.format(cell):''}</span>
              )
            }
        }, 
        {
            dataField: "article.precio",
            text:"Precio AB",
            headerClasses: 'text-right',
            style: { textAlign: 'right' },
            formatter:(cell, row)=>{
                return (
                <>
                  {row.article && <OverlayTrigger overlay={<Tooltip id="mov-detail-tooltip">{row.article.nombre_completo}</Tooltip>} >
                    <span>{cell?numberFormat.format(cell):''}</span>
                  </OverlayTrigger>}
                </>)
            }
        }, 

        {
          dataField: "acciones",
          text: "",
          dummyField: true,
          classes:"text-right",
          headerClasses: "text-center",
          style: {
            minWidth: "100px",
          },
          formatter: (cell, row)=>{
              return (
                <>
                  <OverlayTrigger overlay={<Tooltip id="mov-detail-tooltip">Ver detalle producto #{row.id}</Tooltip>} >
                      <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1" onClick={() => {viewProduct(row)}} > <span><i className="far fa-eye text-primary"></i></span> </a>
                  </OverlayTrigger>
                  {(!row.article || !row.article.reg) && <OverlayTrigger overlay={<Tooltip id="mov-detail-tooltip">Vincular con producto de alfabeta</Tooltip>} >
                      <a className="btn btn-icon btn-light btn-hover-success btn-sm mx-1" onClick={() => {setAlfabetaLink(row)}} > <span><i className="fa fa-link text-success"></i></span> </a>
                  </OverlayTrigger>}
                  {row.article && row.article.reg && <OverlayTrigger overlay={<Tooltip id="mov-detail-tooltip">Desvincular de alfabeta</Tooltip>} >
                      <a className="btn btn-icon btn-light btn-hover-danger btn-sm mx-1" onClick={() => {unsetAlfabetaLink(row)}} > <span><i className="fa fa-link text-danger"></i></span> </a>
                  </OverlayTrigger>}
                </>
            )
          }
        },

    ];
  
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber,
        paginationTotalRenderer: (from, to, size) => (
          <span className="react-bootstrap-table-pagination-total">
            Mostrando { from } a { to } de { size } Resultados
          </span>
        )
      };    


  return (
    <>
        
        <AlfabetaLinkDialog show={showAlfabetaLink} onHide={onHide} product={product} refresh={refresh} setRefresh={setRefresh}></AlfabetaLinkDialog>

        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
                return (

                <Pagination paginationProps={paginationProps} >

                    <BootstrapTable
                        wrapperClasses="table-responsive"
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        remote 
                        keyField="id"
                        data={ entities === null ? [] : entities }
                        columns={ columns }
                        bordered={false}  
                        onTableChange={ getHandlerTableChange( setQueryParams) }
                        {...paginationTableProps}
                        >
                    </BootstrapTable>

                </Pagination>
            );
            }}
        </PaginationProvider>


    </>
  );
}

















